<template>
  <div class="order-product-table">
    <div class="mb-2">
      <span class="mr-1">Cửa hàng:</span
      ><b v-if="orderInfo.storeId">{{ orderInfo.storeName }}</b>
      <b v-else>Chưa được gán</b>
    </div>
    <b-row class="mb-5">
      <b-col cols="2" class="pr-0">
        <b-form-select
          class="select-style"
          v-model="productSearch.searchType"
          :options="optionProductSearchType"
          size="sm"
          value-field="id"
          text-field="name"
        ></b-form-select>
      </b-col>
      <b-col cols="10" class="pl-0">
        <treeselect
          :load-options="loadOptionProducts"
          :multiple="false"
          :async="true"
          :placeholder="productSearch.placeHolder"
          :cache-options="false"
          retryText="Thử lại..."
          retryTitle="Nhấp thử lại"
          searchPromptText="Nhập để tìm kiếm..."
          loadingText="Đang tải..."
          noResultsText="Không có kết quả"
          openDirection="bottom"
          @select="onSelectProduct"
          :always-open="isOpenProductSearch"
          @search-change="onSearchChange"
        >
          <label
            slot="option-label"
            slot-scope="{ node, labelClassName }"
            :class="labelClassName"
          >
            <template>
              <div>
                <b v-if="getInstallmentInterestZero" class="text-warning">
                  ( {{ convertPrice(node.raw.listedPrice) }} )</b
                >
                <b v-else class="text-primary"
                  >( {{ convertPrice(node.raw.sellingPrice) }} )</b
                >
                <b
                  :class="
                    node.raw.quantityInStock > 0
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  ( TCB:{{ node.raw.quantityInStock }} )
                </b>

                {{ node.raw.label }}
                <span
                  v-if="
                    node.raw.imeiCode && node.raw.imeiCode.trim().length > 0
                  "
                  class="text-warning"
                >
                  - IMEI:
                  {{ node.raw.imeiCode }}
                </span>
              </div>
            </template>
          </label>
        </treeselect>
      </b-col>
    </b-row>

    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th class="header-color text-center">STT</th>
          <th class="header-color text-center">Sản phẩm</th>
          <th class="header-color text-center">Số lượng</th>
          <th class="header-color text-center">Giá</th>
          <th class="header-color text-center">Thành tiền</th>
          <th class="header-color text-center">Chiết khấu</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-for="(productItem, index) in orderItems" :key="index">
        <tr
          v-if="
            isItemMainDisplay(productItem.orderItemType) &&
            getInstallmentInterestZero
          "
        >
          <td></td>
          <td :colspan="6">
            <span class="text-danger"> Chương trình trả góp 0% :</span>
            {{ getInstallmentProgram.installmentProgramName }}
          </td>
        </tr>
        <tr v-show="!productItem.deleted" class="heighRow">
          <td class="text-center" style="width: 4%">
            <span
              class="font-weight-bolder"
              v-if="isItemMainDisplay(productItem.orderItemType)"
              >{{ productItem.stt }}</span
            >
            <span v-else>
              <b-form-checkbox
                v-if="false"
                v-model="productItem.checked"
                size="lg"
                @change="checkValidation(productItem)"
                :disabled="!editable"
              ></b-form-checkbox>
              <i class="fas fa-gift"></i>
            </span>
          </td>
          <td style="width: 35%">
            <div>
              <p class="m-0" style="font-weight: 500">
                {{ getTextProductCode(productItem) }}
              </p>
              <p class="m-0 font-weight-bolder">
                {{ productItem.productName }}
                <MerchantLabel :merchantId="productItem.merchantId" />
              </p>
              <b-input-group
                v-if="productItem.productType === PRODUCT_TYPE.PRODUCT_IMEI"
                class="row col-8 btn-imei"
              >
                <b-form-input
                  size="sm"
                  v-model="productItem.imeiCode"
                  placeholder="Nhập mã IMEI"
                  :disabled="true"
                  autocomplete="off"
                ></b-form-input>
                <template #append>
                  <b-button
                    size="sm"
                    variant="secondary"
                    title="Danh sách IMEI"
                    class="font-weight-bolder"
                    v-b-tooltip.right
                    @click="addProductImei(productItem)"
                  >
                    <i class="fas fa-list-ul text-dark"></i>
                  </b-button>
                </template>
                <b-form-invalid-feedback
                  :state="$v.itemChoice.imeiCode.minLength"
                  >Vui lòng nhập imei code > 3 kí tự</b-form-invalid-feedback
                >
                <b-form-invalid-feedback :state="validate"
                  >Không đủ tồn kho sản phẩm</b-form-invalid-feedback
                >
              </b-input-group>
              <div
                v-show="productItem.comboItems && productItem.comboItems.length"
              >
                <table class="table-responsive">
                  <tr
                    v-for="(items, index) in productItem.comboItems"
                    :key="index"
                    style="border-top: 1px solid gray"
                  >
                    <td style="border: none">
                      <i style="color: rgb(24, 28, 50)" class="fas fa-cube"></i>
                    </td>
                    <td style="border: none; width: -webkit-fill-available">
                      {{ items.productName }}
                      <i style="font-size: unset; color: unset"
                        >({{ convertPrice(items.sellingPrice) }})</i
                      >
                    </td>
                    <td style="border: none">{{ items.quantity }}</td>
                  </tr>
                </table>
              </div>
              <div v-if="isItemMainDisplay(productItem.orderItemType)">
                <div v-show="productItem.note || productItem.isPrivateNote">
                  <b-button
                    size="sm"
                    disabled
                    style="color: black; cursor: auto"
                    class="mr-1 font-italic btn-hidden"
                    :id="`popover-note-${index}`"
                  >
                    Ghi chú:
                  </b-button>
                  <span class="text-note"> {{ productItem.note }}</span>
                  <b-popover
                    ref="popover"
                    :target="`popover-note-${index}`"
                    placement="righttop"
                  >
                    <template #title>
                      <b-button
                        @click="
                          onHiddenPrivateNote(
                            productItem,
                            `popover-note-${index}`,
                          )
                        "
                        class="close btn-hidden"
                        aria-label="Close"
                        style="background-color: transparent"
                      >
                        <span class="d-inline-block" aria-hidden="true"
                          >&times;</span
                        >
                      </b-button>
                      Sửa ghi chú sản phẩm
                    </template>
                    <div style="width: 200px">
                      <b-form-group>
                        <label>Ghi chú:</label>
                        <b-form-textarea
                          size="sm"
                          class="input-style"
                          v-model="productItem.popPrivateNote"
                          :placeholder="'Thêm ghi chú'"
                          :rows="6"
                          :max-rows="6"
                        ></b-form-textarea>
                      </b-form-group>

                      <b-button
                        @click="
                          onSavePrivateNote(
                            productItem,
                            `popover-note-${index}`,
                          )
                        "
                        size="sm"
                        variant="primary"
                        class="mr-1"
                        >Lưu</b-button
                      >
                      <b-button
                        @click="
                          onHiddenPrivateNote(
                            productItem,
                            `popover-note-${index}`,
                          )
                        "
                        size="sm"
                        variant="danger"
                        >Huỷ</b-button
                      >
                    </div>
                  </b-popover>
                </div>
              </div>
            </div>
          </td>
          <td class="td-middle text-right" style="width: 10%">
            <div
              v-if="
                [PRODUCT_TYPE.PRODUCT, PRODUCT_TYPE.PRODUCT_COMBO].includes(
                  productItem.productType,
                )
              "
            >
              <BInputNumber
                v-if="editable"
                v-model="productItem.quantity"
                style="border-color: #007bff"
                placeholder="Số lượng"
                :inputClass="'w-100 text-right'"
                :disabled="!editable"
              />
            </div>
            <div v-else>
              {{ productItem.quantity }}
            </div>
          </td>
          <td class="td-middle text-right" style="width: 12%">
            <div v-if="isItemMainDisplay(productItem.orderItemType)">
              <div v-if="checkPermissions(['BILL_EDIT_PRODUCT_PRICE'])">
                <BInputNumber
                  v-if="editable"
                  v-model="productItem.sellingPriceMask"
                  style="border-color: #007bff"
                  placeholder="Đơn giá"
                  :inputClass="'w-100 text-right'"
                  :disabled="!editable"
                />
              </div>
              <div v-else>
                <span class="d-block">
                  {{ convertPrice(productItem.sellingPriceMask) }}
                </span>
              </div>
            </div>
            <div v-else>
              {{ convertPrice(productItem.sellingPrice) }}
            </div>
          </td>
          <td class="td-middle text-right" style="width: 12%">
            <span v-if="isItemMainDisplay(productItem.orderItemType)">
              {{ convertPrice(calculateTotalAmountOrderItems(productItem)) }}
            </span>
            <div v-else class="mb-2">
              <div
                v-if="
                  [ITEM_TYPE.GIFT, ITEM_TYPE.ATTACH].includes(
                    productItem.orderItemType,
                  )
                "
              >
                <span class="text-primary">{{
                  productItem.orderItemType === ITEM_TYPE.GIFT
                    ? 'Giá thu lại'
                    : 'Giá chiết khấu'
                }}</span>
                <br />
                <BInputNumber
                  v-if="editable"
                  v-model="productItem.repurchasePrice"
                  style="border-color: #007bff"
                  :placeholder="
                    productItem.orderItemType === 2
                      ? 'Giá thu lại'
                      : 'Giá chiết khấu'
                  "
                  :inputClass="'w-100 text-right'"
                  :disabled="!editable"
                />
                <span v-else>{{
                  productItem.quantity > 0
                    ? convertPrice(productItem.repurchasePrice)
                    : 0
                }}</span>
              </div>
            </div>
          </td>
          <td class="td-middle text-right" style="width: 12%">
            <div v-if="isItemMainDisplay(productItem.orderItemType)">
              <div v-if="editable">
                <BInputNumber
                  v-model="productItem.discountAmount"
                  style="border-color: #007bff"
                  placeholder="Chiết khấu"
                  :inputClass="'w-100 text-right'"
                  :disabled="!editable"
                />
              </div>
              <div v-else>
                {{ convertPrice(productItem.discountAmount) || '' }}
              </div>
              <b-form-invalid-feedback
                :state="validateDiscountArr[productItem.id]"
              >
                {{ messageErrorDiscount }}
              </b-form-invalid-feedback>
            </div>
          </td>
          <td class="td-middle" style="width: 10%" v-if="editable">
            <div class="d-flex justify-content-center">
              <b-dropdown
                size="sm"
                no-caret
                right
                v-if="productItem.orderItemType === 1"
                :disabled="!editable"
              >
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="addProductGift(productItem)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="fas fa-gift"></i>
                    &nbsp; Thêm quà tặng
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!getInstallmentInterestZero"
                  @click.prevent="addProductAttach(productItem)"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="fas fa-tags"></i>
                    &nbsp; Thêm SP bán kèm
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="
                    onShowPrivateNote(productItem, `popover-note-${index}`)
                  "
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-notepad"></i>
                    &nbsp; Thêm ghi chú
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!getInstallmentInterestZero"
                  @click="changeProduct(productItem, 'main')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                    &nbsp; Đổi sản phẩm
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(productItem)"
                  v-if="!isKeepStock"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown :disabled="!editable" size="sm" no-caret right v-else>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-settings pr-0"
                  ></i>
                </template>
                <b-dropdown-item @click="changeProduct(productItem, 'gift')">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                    &nbsp; Đổi quà tặng
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(productItem)"
                  v-if="!isKeepStock"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th style="text-align: end" colspan="4">Tổng :</th>
          <td style="text-align: right; padding: 0px 20px; font-weight: 600">
            {{ formattedTotalPrice }}
          </td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <OrderItemsModal
      @receives="receiveProducts"
      @receives-imei="receiveProductsImei"
      :parent="parent"
      :mode="mode"
      :params="params"
    />
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { PRODUCT_TYPE, ITEM_TYPE, MODAL_TYPE_UPSERT_ORDER } from '@/utils/enum';
import { validationMixin } from 'vuelidate';
import { minLength } from 'vuelidate/lib/validators';
import OrderItemsModal from '@/view/components/order/OrderItemsModal';
import MerchantLabel from '../../components/MerchantLabel';
import Swal from 'sweetalert2';
import {
  makeToastFaile,
  makeToastSuccess,
  cloneDeep,
  convertPrice,
} from '@/utils/common';
import calculateMixin from '@/view/mixins/calculate.mixins.js';
import { mapGetters } from 'vuex';
import { checkPermissions } from '@/utils/saveDataToLocal';
import {
  PURGE_INSTALLMENT_PROGRAM,
  SET_INSTALLMENT_PROGRAM,
} from '../../../core/services/store/modules/payment/payment-installment.module';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';
import { currencyMask, unMaskPrice } from '@/utils/common';

const SEARCH_TYPE = {
  PRODUCT: 1,
  IMEI: 2,
};

export default {
  props: ['editable', 'otherParams'],
  mixins: [validationMixin, calculateMixin],
  validations: {
    itemChoice: {
      imeiCode: {
        minLength: minLength(3),
      },
    },
  },
  data() {
    return {
      ITEM_TYPE,
      PRODUCT_TYPE,
      productSearch: {
        searchType: SEARCH_TYPE.PRODUCT,
        placeHolder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        searchName: null,
      },
      filteredOptionsProduct: [],
      orderItems: [],
      productItem: {},
      itemChoice: {
        id: 0,
        name: '',
        productId: '',
        imeiCode: '',
        price: 0,
        quantity: 0,
        discount: 0,
        totalPrice: 0,
      },
      isOpenProductSearch: false,
      productSearchTemp: null,
      money: {
        decimal: '.',
        thousands: '.',
        masked: false,
        precision: 0,
      },
      isKeepStock: false,
      watchTracker: [],
      mode: null,
      parent: null,
      params: {},
      orderInfo: null,
      validate: true,
      optionProductSearchType: [],
      mask: currencyMask,
      validateDiscountArr: [],
      messageErrorDiscount: 'CK vượt giá trị SP',
    };
  },
  components: {
    Treeselect,
    OrderItemsModal,
    MerchantLabel,
    BInputNumber,
  },
  watch: {
    'productSearch.searchType'(type) {
      const name = [
        '',
        'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        'Tìm kiếm theo mã imei',
      ];
      this.productSearch.placeHolder = name[type];
    },
    getOrderInfo: {
      handler(value) {
        console.log('getOrderInfo changed component product');
        this.orderInfo = value;
        this.params.storeId = value.storeId;
      },
      deep: true,
    },
    getInstallmentInterestZero: {
      handler(value) {
        this.fetchProgramByProductIds(value);
      },
      deep: true,
    },
  },
  created() {
    this.orderItems = this.getProducts;
    this.orderInfo = { ...this.getOrderInfo };
    this.params.storeId = this.orderInfo.storeId;
    this.initialData();
  },
  mounted() {},
  computed: {
    ...mapGetters([
      'getProducts',
      'getOrderInfo',
      'getInstallmentInterestZero',
      'getInstallmentProgram',
    ]),
    formattedSalary() {
      return this.rawSalary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    formattedTotalPrice() {
      let totalPrice = 0;
      this.orderItems.map((item) => {
        if (item.orderItemType !== ITEM_TYPE.GIFT) {
          const totalPriceItem = this.calculateTotalAmountOrderItems(item);
          totalPrice += totalPriceItem;
        }
      });
      return convertPrice(totalPrice);
    },
  },
  methods: {
    convertPrice,
    checkPermissions,
    initialData() {
      this.optionProductSearchType.push(
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        {
          id: 2,
          name: 'Tìm sản phẩm imei',
        },
      );
    },
    isItemMainDisplay(value) {
      return value === ITEM_TYPE.MAIN ? true : false;
    },
    getTextProductCode(item) {
      let productCode = '';
      switch (item.orderItemType) {
        case ITEM_TYPE.MAIN: {
          if (item.productType !== PRODUCT_TYPE.PRODUCT_COMBO) {
            productCode = `(${item.productCode})`;
          }
          break;
        }
        case ITEM_TYPE.GIFT: {
          productCode = 'Quà tặng kèm';
          break;
        }
        case ITEM_TYPE.ATTACH: {
          productCode = 'Sản phẩm bán kèm';
          break;
        }
      }
      return productCode;
    },
    changeProduct(item, action) {
      if (action === 'gift') {
        this.mode = MODAL_TYPE_UPSERT_ORDER.CHANGE_GIFT;
      } else if (action === 'main') {
        this.mode = MODAL_TYPE_UPSERT_ORDER.CHANGE_MAIN;
      }
      this.parent = item;

      this.$bvModal.show('modal-edit-order-item');
    },
    addProductImei(item) {
      this.parent = item;
      this.mode = MODAL_TYPE_UPSERT_ORDER.PRODUCT_IMEI;
      this.params.orderItems = cloneDeep(this.orderItems);
      this.$bvModal.show('modal-edit-order-item');
    },
    addProductGift(item) {
      this.parent = item;
      this.mode = MODAL_TYPE_UPSERT_ORDER.GIFT;
      this.$bvModal.show('modal-edit-order-item');
    },
    addProductAttach(item) {
      this.parent = item;
      this.mode = MODAL_TYPE_UPSERT_ORDER.ATTACH;
      this.$bvModal.show('modal-edit-order-item');
    },
    async showDeleteAlert(product) {
      const text = `Bạn muốn xoá sản phẩm ${product.productName} ra khỏi danh sách`;
      const alert = await this.showWarningAlert(text);

      if (alert.isConfirmed) {
        const index = this.getIndexOrderItems(product.id);

        if (index > -1) {
          const countChildsItem = this.orderItems.filter(
            (item) => item.belongOrderDetailId === product.id,
          ).length;
          this.orderItems.splice(index, countChildsItem + 1);
          this.numberedOrderItems();
        }
      }
    },
    numberedOrderItems() {
      let count = 1;
      return this.orderItems.map((item) => {
        if (item.orderItemType === ITEM_TYPE.MAIN) {
          item.stt = count;
          count++;
        }
        return item;
      });
    },
    onSearchChange(searchQuery) {
      if (searchQuery.length > 1 && !this.isOpenProductSearch) {
        this.isOpenProductSearch = true;
      } else if (searchQuery.length === 0) {
        this.isOpenProductSearch = false;
      }
    },
    isAvailableQuantity: async function (value) {
      this.validate = false;
      let dataResponse = {
        ...this.itemChoice,
        id: this.productItem.id,
        productId: this.productItem.productId,
        imeiCode: value,
        name: this.productItem.name,
        quantity: this.productItem.quantity,
        price: this.productItem.price,
        totalPrice: this.totalPriceOfProduct,
        discount: this.productItem.discount,
      };

      if (value === '') {
        this.$emit('update', dataResponse);
        return true;
      }

      const params = {
        storeId: this.orderInfo.storeId,
        imei: this.itemChoice.imeiCode,
      };

      await ApiService.query('bills/check-valid-imei', {
        params,
      })
        .then((response) => {
          if (response.data.data) {
            this.validate = true;
            this.$emit('checkValidImei', this.validate);
            this.$emit('update', dataResponse);
          }
          return this.validate;
        })
        .catch(() => {
          this.$emit('checkValidImei', this.validate);
          this.$emit('update', dataResponse);
          return this.validate;
        });

      return false;
    },

    async loadOptionProducts({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        this.productSearchTemp = searchQuery;
        const type = this.productSearch.searchType;
        this.productSearchTemp = searchQuery;
        const result = await this.searchProductAPI(searchQuery, type);

        const optionProduct = (result.data || []).map((element) => {
          if (type === PRODUCT_TYPE.PRODUCT) {
            return {
              id: this.$uuid.v4(),
              label: element.productName,
              ...element,
            };
          } else {
            return {
              id: this.$uuid.v4(),
              label: element.productName,
              imeiCode: element.imeiCode,
              ...element,
            };
          }
        });

        callback(null, optionProduct);
      }
    },
    searchProductAPI(searchQuery, type) {
      let products = [];
      switch (type) {
        case SEARCH_TYPE.PRODUCT:
          products = this.fetchProduct(searchQuery, type);
          break;
        case SEARCH_TYPE.IMEI:
          products = this.fetchProductImei(searchQuery, type);
          break;
        default:
          break;
      }
      return products;
    },
    fetchProduct(textSearch) {
      this.listProduct = [];

      const params = {
        searchProduct: textSearch ? textSearch.trim() : null,
        storeId: this.orderInfo.storeId || null,
        isInterestZero: this.getInstallmentInterestZero,
      };
      const url = params.isInterestZero
        ? 'productSearch/search'
        : 'productSearch';
      return ApiService.query(url, { params }).then((response) => {
        return response.data;
      });
    },
    fetchProductImei(textSearch) {
      this.listProduct = [];
      const storeId = this.orderInfo.storeId || null;

      if (!storeId) {
        makeToastFaile('Chưa chọn cửa hàng');
        return [];
      }

      const params = {
        imeiCode: textSearch ? textSearch.trim() : null,
        storeId: storeId,
      };

      return ApiService.query(`productSearch/search-by-imei`, { params }).then(
        (response) => {
          return response.data;
        },
      );
    },
    onSelectProduct(node) {
      this.isOpenProductSearch = false;
      let countStt = this.orderItems.filter(
        (item) => item.orderItemType === ITEM_TYPE.MAIN,
      ).length;

      const item = this.handleOrderItemSelected(node, ++countStt);
      this.orderItems.push(item);
    },
    handleOrderItemSelected(item, stt) {
      const itemSelected = cloneDeep(item);
      const isInterestZero = this.getInstallmentInterestZero;
      const sellingPrice = item.sellingPrice;

      item.sellingPrice = sellingPrice;
      itemSelected.orderItemType = ITEM_TYPE.MAIN;
      itemSelected.quantity = 1;
      itemSelected.sellingPriceMask = sellingPrice;
      itemSelected.stt = stt;
      itemSelected.note = '';
      itemSelected.discountType = 1;
      itemSelected.discountAmount = 0;
      itemSelected.isPrivateNote = false;
      itemSelected.popPrivateNote = '';
      itemSelected.comboItems = item.listProductInCombo || [];
      this.selectInstallmentProgram(isInterestZero, item.programs);
      return itemSelected;
    },
    checkValidation(productItem) {
      // this.error = false;
      // let dataResponse = this.productItem;
      // dataResponse.price = unMaskPrice(this.productItem.price);
      // dataResponse.totalPrice = unMaskPrice(this.productItem.totalPrice);
      // if (this.productItem.orderItemType === 1) {
      //   dataResponse.discount = unMaskPrice(this.productItem.discount);
      // } else {
      //   dataResponse.discount = 0;
      // }
      // this.$emit('update', dataResponse);
      if (unMaskPrice(productItem.discountAmount) > productItem.sellingPrice) {
        this.$set(this.validateDiscountArr, productItem.id, false);
      } else {
        this.$set(this.validateDiscountArr, productItem.id, true);
      }
    },
    receiveProducts(product) {
      switch (this.mode) {
        case MODAL_TYPE_UPSERT_ORDER.CHANGE_GIFT: {
          const changedProduct = product.childs ? product.childs[0] : null;
          if (changedProduct) {
            const getProduct = (item) => item.id === product.id;
            const productIndex = this.orderItems.findIndex(getProduct);
            const currentProductId = product.id;
            changedProduct.id = currentProductId;
            changedProduct.orderItemType = ITEM_TYPE.GIFT;
            changedProduct.checked = true;
            changedProduct.deleted = false;
            this.$set(this.orderItems, productIndex, changedProduct);
          }
          break;
        }
        case MODAL_TYPE_UPSERT_ORDER.CHANGE_MAIN: {
          const changedProduct = product.childs ? product.childs[0] : null;
          if (changedProduct) {
            const getProduct = (item) => item.id === product.id;
            const productIndex = this.orderItems.findIndex(getProduct);
            const currentProductId = product.id;

            const newProduct = Object.assign(product, changedProduct);
            newProduct.sellingPriceMask = newProduct.sellingPrice;
            newProduct.id = currentProductId;
            this.orderItems.splice(productIndex, 1, newProduct);
          }
          break;
        }
        default: {
          const childs = product.childs || [];
          const getParent = (item) => item.id === product.id;
          const countChilds = (item) => item.belongOrderDetailId === product.id;

          const parentIndex = this.orderItems.findIndex(getParent);
          let childsCount = this.orderItems.filter(countChilds).length;

          childs.map((child) => {
            const addIndex = parentIndex + childsCount + 1;
            child.belongOrderDetailId = product.id;
            child.orderItemType = ITEM_TYPE.GIFT;
            child.checked = true;
            child.deleted = false;
            this.orderItems.splice(addIndex, 0, child);
            childsCount++;
          });
          break;
        }
      }
    },
    receiveProductsImei(product, remove) {
      const getParent = (item) => item.id === product.id;
      const parentIndex = this.orderItems.findIndex(getParent);
      if (remove) {
        product.imeiCode = null;
      } else {
        product.imeiCode = product.productImei.imeiCode;
        product.sellingPrice = product.productImei.sellingPrice;
      }
      this.parent = null;
      this.$set(this.orderItems, parentIndex, product);
    },
    showWarningAlert: function (text) {
      return Swal.fire({
        title: 'Xoá sản phẩm!',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    },
    getIndexOrderItems(id) {
      const index = this.orderItems.findIndex((item) => item.id === id);
      if (index < 0) {
        makeToastFaile(`Không tìm thấy ${id} trong danh sách`);
        throw new Error(`Không tìm thấy ${id} trong danh sách`);
      }
      return index;
    },
    onShowPrivateNote(item, ref) {
      for (let i = 0; i < this.orderItems.length; i++) {
        this.$root.$emit('bv::hide::popover', `popover-note-${i}`);
      }
      const index = this.getIndexOrderItems(item.id);
      item.isPrivateNote = true;
      item.popPrivateNote = item.note;
      this.$set(this.orderItems, index, item);
      setTimeout(() => {
        this.$root.$emit('bv::show::popover', ref);
      }, 300);
    },
    onHiddenPrivateNote(item, ref) {
      const index = this.getIndexOrderItems(item.id);
      item.isPrivateNote = false;
      this.$root.$emit('bv::hide::popover', ref);

      setTimeout(() => {
        this.$set(this.orderItems, index, item);
      }, 300);
    },
    onSavePrivateNote(item, ref) {
      const index = this.getIndexOrderItems(item.id);
      item.note = item.popPrivateNote.trim();

      if (item.note) {
        this.$set(this.orderItems, index, item);
        this.$root.$emit('bv::hide::popover', ref);
        makeToastSuccess('Thêm ghi chú thành công');
      } else {
        makeToastSuccess('Ghi chú không có nội dùng');
        item.isPrivateNote = false;
        this.$root.$emit('bv::hide::popover', ref);
        setTimeout(() => {
          this.$set(this.orderItems, index, item);
        }, 300);
      }
    },
    fetchProgramByProductIds(isInterestZero) {
      const items = cloneDeep(this.orderItems);
      const productIds = items.map((item) => item.productId);
      if (!productIds.length) {
        return;
      }
      const params = {
        productIds: productIds,
        isInterestZero: isInterestZero,
      };
      ApiService.query('installment-programs/product-ids', {
        params: params,
      }).then((response) => {
        const { data } = response.data;
        let programs = [];
        items.map((item, index) => {
          const productFinded = data.find(
            (product) => product.productId === item.productId,
          );
          if (productFinded) {
            item.sellingPrice = productFinded.programPrice;
            item.sellingPriceMask = productFinded.programPrice;
            programs = programs.concat(productFinded.programs);
            this.$set(this.orderItems, index, item);
          }
        });
        this.selectInstallmentProgram(isInterestZero, programs);
      });
    },
    selectInstallmentProgram(isInterestZero, programs) {
      if (isInterestZero && programs && programs.length) {
        const firstChoiceProram = programs[0];
        if (firstChoiceProram) {
          this.$store.dispatch(SET_INSTALLMENT_PROGRAM, {
            installmentProgramId: firstChoiceProram.id,
            installmentProgramName: firstChoiceProram.name,
            monthNo: firstChoiceProram.monthNo,
            installmentProgramMonthNo: null,
            details: programs,
            installmentProgramPrepayPercent: null,
            prepayPercent: firstChoiceProram.prepayPercent,
            conversionFeePercent: firstChoiceProram.conversionFeePercent,
          });
        }
      } else {
        this.$store.dispatch(PURGE_INSTALLMENT_PROGRAM);
      }
    },
  },
};
</script>

<style lang="scss">
.order-product-table {
  .form-group {
    margin-bottom: 1rem;
  }
  table {
    td {
      vertical-align: middle !important;
      // word-wrap: break-word;
      // word-break: normal;
      // word-break: break-all;
      // word-break: keep-all;
      // word-break: break-word; /* deprecated */
    }
  }
  .vue-treeselect__input-container {
    padding-top: 0px;
  }

  .vue-treeselect__control {
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    height: 2.5rem;
  }

  .vue-treeselect--has-value .vue-treeselect__multi-value {
    margin-bottom: 2px;
  }

  .header-color {
    color: rgb(24, 28, 50);
  }
  .text-note {
    font-size: 12px;
    font-style: italic;
    white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap; /* Chrome & Safari */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* CSS3 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    word-break: break-word;
    white-space: normal;
  }
  .btn-hidden {
    padding: 0px;
    border: none;
    background-color: transparent !important;
  }
  .btn-imei .btn.btn-secondary {
    // background-color: #dbdbff;
    background-color: #cac9db;
    border-color: #ced4da;
  }
}
</style>
