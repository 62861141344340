<template>
  <div>
    <b-dropdown
      class="w-100"
      id="dropdown-form"
      ref="dropdown"
      size="sm"
      variant="outline-secondary"
    >
      <template #button-content>
        <span class="truncate-content-placeholder float-left">
          <span v-if="selectedValue.length">
            <span v-if="!options.length"> ...Đang tải dữ liệu </span>
            <span v-else>
              {{
                selectedValue.length === 1
                  ? selectedValue[0][textProp]
                  : `${selectedValue.length} ${placeholder} đã chọn`
              }}
            </span>
          </span>
          <span v-else>{{ `Chọn ${placeholder}` }}</span>
        </span>
      </template>
      <div v-if="options && options.length">
        <div class="p-2">
          <b-input-group size="sm" class="mb-2">
            <b-form-input
              v-show="searchable"
              ref="search-input"
              type="search"
              v-model="searchValue"
              :placeholder="`Tìm kiếm ${placeholder}`"
              autocomplete="off"
              v-focus
            ></b-form-input>
          </b-input-group>

          <div class="w-100 mb-2">
            <span v-b-toggle.collapse-dd-filter>
              <b-badge variant="secondary">
                <i
                  class="fa fa-filter text-primary cursor-pointer align-middle font-size-1rem"
                ></i>
              </b-badge>
            </span>
          </div>

          <b-collapse id="collapse-dd-filter" class="mb-2">
            <b-form-group class="mb-2 mt-2" v-if="optionsMasterStore.length">
              <b-form-checkbox-group
                class="ml-1"
                v-model="optionsMasterSelected"
                :options="optionsMasterStore"
                value-field="storeType"
                text-field="storeName"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
          </b-collapse>
          <b-button-group size="sm" class="w-100 mb-2">
            <b-button
              variant="secondary"
              class="font-weight-bolder w-50 p-1"
              @click="onSelectAll"
            >
              <span> Chọn {{ filterTotalOptions.length }} </span>
            </b-button>
            <b-button
              variant="secondary"
              class="font-weight-bolder w-50 p-1"
              @click="unSelectAll"
              >Bỏ chọn</b-button
            >
          </b-button-group>
          <div class="w-100">
            <span class="font-weight-bolder align-middle"
              >Tổng
              {{
                filteredOptions.length + '/' + filterTotalOptions.length
              }}</span
            >
            <span class="float-right" v-if="roundTotalChunks > 0">
              <a
                href="javascript:void(0)"
                class="previous cursor-pointer font-weight-bolder"
                @click="onPreviousItem"
                >&laquo;
              </a>
              <span
                >{{ roundTotalChunks > 0 ? cursorChunk + 1 : 0 }} /
                {{ roundTotalChunks }}</span
              >
              <a
                href="javascript:void(0)"
                class="next cursor-pointer font-weight-bolder"
                @click="onNextItem"
              >
                &raquo;</a
              >
            </span>
          </div>
        </div>
        <div
          class="p-2 d-flex"
          v-if="!filteredOptions.length && searchValue.trim().length"
        >
          <i
            class="fa-solid fa-circle-exclamation text-danger mr-1 d-flex align-items-center"
          ></i>
          <b class="align-items-center"> Không tìm thấy kết quả</b>
        </div>
        <div
          class="ul-drop-down-item pr-2 pl-2 pb-1"
          v-if="filteredOptions.length"
        >
          <ul v-for="(item, index) in filteredOptions" :key="index">
            <li
              class="dropdown-item"
              :class="{
                selected: isSelectedOption(item, index),
              }"
              :title="`Tên: ${item[textProp]} Mã: ${item.shortName} `"
              v-on:click="
                !isSelectedOption(item, index)
                  ? onSelectItem(item, index)
                  : unSelectItem(item, index)
              "
            >
              <div
                class="truncate-content"
                :title="`Tên: ${item[textProp]} Mã: ${item.shortName} `"
              >
                <i
                  v-if="isSelectedOption(item, index)"
                  class="fa fa-check-circle fa-sm text-white align-middle"
                ></i>
                <i
                  v-else
                  class="fa-regular fa-circle fa-sm text-dark align-middle"
                ></i>
                <slot name="text" :item="item">
                  <span class="ml-1 align-middle">{{ item[textProp] }} </span>
                </slot>
              </div>
            </li>
          </ul>
        </div>
        <div class="p-2 pb-1 d-flex">
          <div class="w-100">
            <b-badge variant="secondary" @click="onResizeHorizontal">
              <i
                class="fa-solid fa-left-right text-primary cursor-pointer font-size-1rem"
              ></i>
            </b-badge>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="p-2">
          <b-icon
            icon="circle-fill"
            animation="throb"
            font-scale="1"
            variant="primary"
            class="mr-1"
          ></b-icon>
          <span>Đang tải dữ liệu</span>
        </div>
      </div>
    </b-dropdown>
  </div>
</template>
  
  <script>
import { removeAccents } from '@/utils/common';
import { cloneDeep } from '@/utils/common';

export default {
  props: {
    value: {
      default: () => [],
      required: true,
    },
    options: {
      default: () => [],
      type: Array,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    textProp: {
      type: String,
      default: 'name',
    },
    valueProp: {
      type: String,
      default: 'id',
    },
    limit: {
      type: Number,
      default: 10,
    },
    matchKey: {
      type: Array,
      default: () => ['name'],
    },
    placeholder: {
      type: String,
      default: 'thông tin',
    },
  },
  model: {
    prop: 'value',
    event: 'valueChange',
  },
  data() {
    return {
      optionsMasterSelected: [],
      searchValue: '',
      originValues: [],
      selectedValue: [],
      cursorChunk: 0,
      searchKey: [],
    };
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', async () => {
      await this.$nextTick();
      if (this.$refs['search-input']) this.$refs['search-input'].focus();
    });
    this.searchKey = cloneDeep(this.matchKey);
  },
  watch: {
    value: {
      handler(values) {
        values.forEach((option) => {
          this.handleCheckedItem(option);
        });
      },
    },
  },
  computed: {
    optionsMasterStore() {
      const uniqueStoreType = this.options.reduce((result, item) => {
        if (item.storeType && !result[item.storeType]) {
          result[item.storeType] = {
            storeType: item.storeType,
            storeName: item.storeName,
          };
        }
        return result;
      }, {});
      return Object.values(uniqueStoreType);
    },
    totalItemLength() {
      return this.options && this.options.length ? this.options.length : 0;
    },
    filterTotalOptions() {
      return this.ftOriginOptions();
    },
    filteredOptions() {
      const fromChunk = this.cursorChunk * this.limit;
      const toChunk = (this.cursorChunk + 1) * this.limit;
      const ftTotalOptions = this.filterTotalOptions;
      return ftTotalOptions.slice(fromChunk, toChunk);
    },
    roundTotalChunks() {
      const ftTotalOptions = this.filterTotalOptions;
      const totalFtOptionsLength = ftTotalOptions.length;
      return Math.ceil(totalFtOptionsLength / this.limit);
    },
  },
  methods: {
    onSelectItem(option) {
      if (!option) return;
      if (option[this.disabledProp]) return;
      this.handleCheckedItem(option);
      this.setVModelProp(this.selectedValue);
    },
    unSelectItem(option) {
      this.handleUncheckedItem(option);
      this.setVModelProp(this.selectedValue);
    },
    handleCheckedItem(option) {
      if (!option) return;
      const itemFound = this.selectedValue.find((select) => {
        return select[this.textProp] === option[this.textProp];
      });
      if (!itemFound) {
        this.selectedValue.push(option);
      }
    },
    handleUncheckedItem(option) {
      this.selectedValue = this.selectedValue.filter((item) => {
        return item[this.valueProp] !== option[this.valueProp];
      });
    },
    ftOriginOptions() {
      if (this.searchable === false) return this.options;
      const isObject = typeof this.options[0] === 'object';
      this.cursorChunk = 0;

      return this.options
        .filter((option) => {
          return this.optionsMasterSelected.length
            ? this.optionsMasterSelected.includes(Number(option.storeType))
            : true;
        })
        .filter((option) => {
          if (!this.searchValue.trim()) {
            return true;
          }
          const textConcatString = this.searchKey.reduce((result, prop) => {
            return (result += option[prop] + ' ');
          }, '');
          const sourceValueTrim = isObject
            ? textConcatString
              ? textConcatString.toLowerCase()
              : textConcatString
            : option
            ? option.trim().toLowerCase()
            : option;
          const destinateValueTrim = this.searchValue
            ? this.searchValue.trim().toLowerCase()
            : '';

          const nameWTUnicode = removeAccents(sourceValueTrim);
          const nameInputWTUnicode = removeAccents(destinateValueTrim);
          return nameWTUnicode.indexOf(nameInputWTUnicode) !== -1;
        });
    },
    onSelectAll() {
      this.filterTotalOptions.forEach((option) => {
        this.handleCheckedItem(option);
      });
      this.setVModelProp(this.selectedValue);
    },
    unSelectAll() {
      this.selectedValue = [];
      this.setVModelProp(this.selectedValue);
    },
    isSelectedOption(option) {
      return !!this.selectedValue.find((selected) =>
        this.isEqualOption(option, selected),
      );
    },
    isEqualOption(a, b) {
      if (a && b && typeof a === 'object' && typeof b === 'object') {
        return (
          a[this.textProp] === b[this.textProp] &&
          a[this.valueProp] === b[this.valueProp]
        );
      }
      return a === b;
    },
    onNextItem() {
      this.cursorChunk = Math.min(
        this.cursorChunk + 1,
        this.roundTotalChunks - 1,
      );
    },
    onPreviousItem() {
      this.cursorChunk = Math.max(this.cursorChunk - 1, 0);
    },
    setVModelProp(values) {
      this.$emit('valueChange', values);
    },
    onResizeHorizontal() {
      const accumulator = 50;
      const DEFAULT_WIDTH = 100;
      const MAX_WIDTH = 250;
      const TRANSITION_SLIDE = 'slide-leave-active';

      const selector = document.querySelector('.dropdown-menu.show');
      const widthMatch = selector.style.width.match(/\d+/g);
      const widthNumber =
        widthMatch && widthMatch.length
          ? Number(widthMatch[0]) >= MAX_WIDTH
            ? DEFAULT_WIDTH
            : Number(widthMatch[0]) + accumulator
          : DEFAULT_WIDTH * 2;

      selector.setAttribute('style', `width:${widthNumber}%;`);
      if (!selector.classList.contains(TRANSITION_SLIDE)) {
        selector.classList.add(TRANSITION_SLIDE);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$white: #ffffff;
$black: #000000;
$dodger-blue: #3699fe;
$ghost: #ced4da;
$pewter: #9a9b9b;
$tundora: #424242;
$hawkes-blue: #e3f2fd;

#dropdown-form ::v-deep {
  .font-size-1rem {
    font-size: 1rem;
  }
  .slide-leave-active {
    transition: all 300ms ease-in-out;
  }
  .truncate-content {
    display: inline-block;
    width: calc(90% - 1px);
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-align: left;
    max-width: 40vw;
  }
  .truncate-content-placeholder {
    @extend .truncate-content;
    max-width: 10vw;
  }

  #dropdown-form__BV_toggle_ {
    border-radius: 0.28rem;
    border: 1px solid $ghost;
  }

  input[type='search']::-webkit-search-cancel-button {
    margin-left: 0.5em;
    -webkit-appearance: searchfield-cancel-button;
    cursor: pointer;
  }

  ul {
    color: $tundora;
    text-align: left;
    list-style: none;
    background-color: $white;
    background-clip: padding-box;
    padding: 0px;
    margin: 2px 0px 0px 0px;
  }
  .ul-drop-down-item {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 40vh;
    cursor: pointer;
  }

  .dropdown-menu.show {
    width: 100%;
  }
  .dropdown-toggle:after {
    position: absolute;
    top: calc(45% - 1px);
    right: 10%;
  }
  .dropdown-item {
    border-radius: 0.28rem;
    text-decoration: none;
    line-height: 13.5px;
    padding: 0.5rem 1rem;
    user-select: none;
    &:hover:not(.default-option) {
      background-color: $hawkes-blue;
      color: $black;
    }
    &.disabled {
      color: $pewter;
    }
    &.selected {
      background-color: $dodger-blue;
      color: $white;
      &:hover {
        background-color: $dodger-blue;
        color: $white;
      }
    }
    &.disabled {
      cursor: not-allowed;
      &:hover {
        background-color: $white;
      }
    }
  }
}
</style>