<template>
  <div class="order-import-excel">
    <b-modal
      id="import-excel-create-orders"
      ref="import-excel-create-orders"
      hide-footer
      title="Nhập excel đơn hàng:"
      no-close-on-backdrop
      size="xl"
    >
      <div>
        <b-row>
          <b-col>
            <div class="form-group">
              <span>
                <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng
                format, hoặc có thể tải ở
                <a :href="urlExcel">đây</a>
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span> <b>Bước 2:</b> Tiến hành Import </span>
            <div style="padding-top: 15px">
              <b-form-file
                @change="selectFile"
                v-model="file"
                ref="file-input"
                style="display: none"
                id="file-input"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ></b-form-file>
              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                    :disabled="btnDisable"
                    variant="secondary"
                    @click="openFile('file-input')"
                    v-b-tooltip.hover
                    title="Chọn file"
                    ><i class="fas fa-paperclip"></i
                  ></b-button>
                </b-input-group-prepend>

                <b-form-input
                  type="text"
                  :disabled="btnDisable"
                  v-model="textFile"
                  placeholder="Hãy chọn một tập tin"
                  @click="openFile('file-input')"
                  v-on:keydown.prevent
                  style="caret-color: transparent; cursor: pointer"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="secondary"
                    :disabled="!file"
                    v-b-tooltip.hover
                    @click="uploadFile"
                    title="Tải file"
                    ref="up_excel"
                  >
                    <i class="fas fa-upload text-primary"></i>
                  </b-button>
                  <b-button
                    variant="secondary"
                    :disabled="!file"
                    @click="clearFiles"
                    v-b-tooltip.hover
                    title="Xoá file"
                    ><i class="fas fa-trash text-danger"></i
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
              <label class="description" style="font-size: 10px">
                Chỉ nhận file csv, xls, xlsx và nhỏ hơn 5MB</label
              >
            </div>
            <div v-show="file" class="mt-4">
              <b-progress :max="100">
                <b-progress-bar
                  :value="processBar"
                  :label="`${processBar.toFixed(2)}%`"
                ></b-progress-bar>
              </b-progress>
            </div>
          </b-col>
        </b-row>
        <!-- End input session -->
      </div>
      <div class="mt-10" v-show="importItems.length !== 0">
        <hr
          class="hr-text"
          data-content="Danh sách đơn hàng"
          style="font-weight: 600"
        />
        <div class="title-sum">
          <ul class="list-inline d-flex">
            <li>
              <b
                >Tổng tiền: 
                <span class="text-success">{{ formattedTotalPrice }}</span>
              </b>
            </li>
          </ul>
        </div>
        <b-table
          class="myTable"
          caption-top
          responsive
          bordered
          hover
          :fields="fields"
          :items="importItems"
          id="my-table"
          :per-page="10"
          :current-page="currentPage"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle ml-3"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(customerName)="data">
            <b> {{ data.item.customerName }}</b>
            <p>{{ data.item.customerMobile }}</p>
            <span v-if="data.item.customerAddress">
              <b>ĐC:</b> {{ data.item.customerAddress }}
            </span>
          </template>
          <template v-slot:cell(productName)="data">
            <div v-if="!data.item.orderItems">
              <span class="font-weight-bolder">{{ data.item.productName }}</span>
            </div>
            <div v-else>
              <div v-for="(item, idx) in data.item.orderItems" :key="idx">
              <p class="font-weight-bolder">{{idx + 1}}) {{ item.productName }}</p>
                <div class="d-flex justify-content-between px-2 my-2">
                  <div> Giá: {{ convertPrice(item.sellingPrice) }} 
                    <p
                      v-b-tooltip.hover.bottom
                      title="Chiết khấu sản phẩm"
                      v-if="item.discountAmount"
                    >
                      <i
                        class="fa fa-arrow-down icon-color"
                        style="font-size: 12px; text-align: center"
                      >
                      </i>:
                      <span class="font-weight-bolder text-warning">
                        {{ convertPrice(item.discountAmount) }}
                      </span>
                    </p>
                  </div>
                  <span>SL: {{ item.quantity }}</span>
                </div>
               
              </div>
            </div>
            
          </template>
          <template v-slot:cell(amountTotal)="data">
            <p v-show="data.item.customerShipFee > 0" class="border-0">
              <i
                v-b-tooltip.hover
                title="Phí ship"
                class="fas fa-shipping-fast icon-color"
              ></i>
              : {{ convertPrice(data.item.customerShipFee) }}
            </p>
            <p v-show="data.item.shipFee > 0" class="border-0">
              <i
                v-b-tooltip.hover
                title="Phí trả hãng vận chuyển"
                class="fas fa-hand-holding-usd icon-color"
              ></i>
              : {{ convertPrice(data.item.shipFee) }}
            </p>
            <p v-show="data.item.discountAmount > 0" class="border-0">
              <i
                v-b-tooltip.hover
                title="Chiết khấu"
                class="fa fa-arrow-down icon-color"
              ></i>
              : {{ convertPrice(data.item.discountAmount) }}
              <span v-if="data.item.discountType === 2"> % </span>
            </p>
            <b style="color: #2e7d32">
              {{ convertPrice(sumAmountTotal(data.item)) }}
            </b>
          </template>

          <template v-slot:cell(note)="data">
            <p v-if="data.item.saleNote && data.item.saleNote.trim()"> <span class="font-weight-bold">Từ CSKH:</span> {{ data.item.saleNote }}</p>
            <p v-if="data.item.customerNote && data.item.customerNote.trim()"> <span class="font-weight-bold">Từ khách:</span> {{ data.item.customerNote }}</p>
          </template>

          <template #cell(statusValidate)="data">
            <div v-if="!data.item.onLoading">
              <div class="d-table-cell align-middle" style="width: 10%">
                <i
                  v-b-tooltip.hover
                  :title="htmlGetStatusValidate(data.item, 'title')"
                  :class="htmlGetStatusValidate(data.item, 'class')"
                ></i>
              </div>
              <div class="d-block align-middle pb-1 pl-2" style="width: 90%">
                <div v-for="(items, i) in data.item.errors" :key="i">
                  <span class="text-danger">{{ items }}</span>
                </div>
              </div>
              <div
                class="d-table-cell align-middle pb-1 pl-2"
                style="width: 90%"
              >
                {{ data.item.statusValidateName }}
              </div>
            </div>
            <div v-else>
              <b-spinner
                small
                variant="primary"
                class="mr-2"
              ></b-spinner>
              <span class="text-primary">Đang xử lí...</span>
            </div>
          </template>
          
          <template #cell(actions)="row">
            <v-icon
              v-if="!hasError || successCreateOrder"
              small
              class="text-danger"
              @click="onRemovedItem(row.item.orderCode)"
              v-b-tooltip
              title="Xóa"
              >mdi-delete</v-icon
            >
          </template>
        </b-table>
        <b-col class="row mt-3">
          <b>
            Tổng số dòng:
            {{ importItems ? importItems.length : 0 }}
          </b>
        </b-col>
        <b-pagination
          v-show="importItems.length > 10"
          v-model="currentPage"
          :total-rows="rows()"
          :per-page="10"
          aria-controls="my-table"
          align="right"
        ></b-pagination>
      </div>
      <b-overlay
        v-if="!hasError && importItems.length"
        :show="onLoadingList"
        rounded
        opacity="0.6"
        class="d-inline-block mr-3"
      >
        <template #overlay>
          <div class="d-flex align-items-center">
            <b-spinner variant="light" small type="grow"></b-spinner>
          </div>
        </template>
        <b-button
          style="width: 80px"
          class="mt-6"
          :disabled="onLoadingList || !importItems.length"
          v-if="importItems.length && !successCreateOrder"
          variant="primary"
          size="sm"
          @click="onCreateOrders"
        >
          <strong>Tạo mới</strong>
        </b-button>
      </b-overlay>

      <b-button
        class="mt-6"
        style="width: 80px"
        variant="secondary"
        size="sm"
        @click="hideModalExcel"
      >
        <strong>Hủy</strong>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { EventBus } from '@/core/services/event-bus';
import { BASE_URL } from '@/utils/constants';
import { cloneDeep, convertPrice, delay } from '@/utils/common';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import * as ENUM_NAME from '@/utils/enum-name';
import * as ENUM from '@/utils/enum';
import ApiService from '@/core/services/api.service';

const STATUS_VALIDATE = {
  INVALID: 0,
  VALID: 1,
  READY: 2,
  LOADING: 3,
};

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  name: 'OrderImportExcel',
  data() {
    return {
      ENUM,
      ENUM_NAME,
      urlExcel: BASE_URL + 'media/upload/orders/Temp_Import_Orders_.xls',
      onLoadingList: false,
      currentPage: 1,
      file: null,
      btnDisable: false,
      processBar: 0,
      importItems: [],
      tempImportItems: [],
      textFile: '',
      fields: [
        {
          key: 'orderCode',
          label: 'ID',
          thStyle: {
            ...thStyleCommon,
            width: '5%'
          },
          formatter: value => {
            return `#${value}`;
          },
          tdClass: 'align-middle'
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          thStyle: {
            ...thStyleCommon,
            width: '5%'
          },
          tdClass: 'align-middle'
        },
        {
          key: 'orderSourceName',
          label: 'Nguồn',
          thStyle: {
            ...thStyleCommon,
            width: '5%'
          },
          tdClass: 'align-middle'
        },
        {
          key: 'orderTypeName',
          label: 'Loại đơn hàng',
          thStyle: {
            ...thStyleCommon,
            width: '5%'
          },
          tdClass: 'align-middle'
        },
        {
          key: 'customerName',
          label: 'Khách hàng',
          thStyle: {
            ...thStyleCommon,
            width: '10%'
          },
          tdClass: 'align-middle'
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: {
            ...thStyleCommon,
            width: '30%'
          },
          tdClass: 'align-middle'
        },
        {
          key: 'amountTotal',
          label: 'Tổng tiền',
          thStyle: {
            ...thStyleCommon,
            width: '10%'
          },
          tdClass: 'text-left'
        },
        {
          key: 'note',
          label: 'Ghi chú',
          thStyle: {
            ...thStyleCommon,
            width: '7%'
          },
          tdClass: 'text-left'
        },
        {
          key: 'statusValidate',
          label: 'Trạng thái',
          thStyle: {
            ...thStyleCommon,
            width: '15%'
          },
          tdClass: 'align-middle text-center'
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle text-center'
        },
      ],
      apiParams: [],
      isBusy: false,
      successCreateOrder: false
    };
  },
  mounted() {
    EventBus.$on('popup-import-orders', this.popupImportExcelCreateOrder);
  },
  beforeDestroy() {
    EventBus.$off('popup-import-orders', this.popupImportExcelCreateOrder);
  },
  computed: {
    hasError() {
      const errors = this.importItems.map(item => item.errors).flat();
      return errors.length > 0;
    },
    formattedTotalPrice() {
      let totalPrice = 0;
      this.importItems.map(item => {
        const orderItemType = item.productType;
        if (orderItemType !== ENUM.ITEM_TYPE.GIFT) {
          const totalPriceItem = this.sumAmountTotal(item);
          totalPrice += totalPriceItem;
        }
      });
      return this.convertPrice(totalPrice);
    },
  },
  methods: {
    delay,
    convertPrice,
    hideModalExcel(){
      this.$refs['import-excel-create-orders'].hide();
      this.clearFiles();
    },
    onCreateOrders: async function() {
      const items = cloneDeep(this.importItems);
      const invalids = items.filter(item => item.errors.length > 0);

      if (invalids.length > 0) {
        return this.makeToastFaile(
          `Đơn hàng không hợp lệ 
          ${invalids.map(item => item.orderCode).join()}`
        );
      }

      if (!this.isBusy) {
        const dataMessages = {
          success: [],
          failed: []
        };

        for (const item of items) {
          const index = this.importItems.findIndex(e => e.orderCode === item.orderCode);

          if (index < 0) {
            this.makeToastFaile('Không tìm thấy đơn hàng.');
            return;
          }
          this.importItems[index].onLoading = true;

          const [status, message] = await ApiService.post('/orders/v2', item)
            .then(response => {
              const { status, message } = response.data;
              return [status, message];
            })
            .catch(err => {
              if (!err.response) {
                // network error
                return [0, 'Lỗi mạng'];
              } else {
                const message = err.response.data.message;
                return [0, message];
              }
            });

          this.importItems[index].onLoading = false;

          if (status) {
            dataMessages.success.push(item);
            this.importItems[index].statusValidate = STATUS_VALIDATE.VALID;
            this.importItems[index].statusValidateName = 'Tạo mới thành công';
          } else {
            this.importItems[index].statusValidate = STATUS_VALIDATE.INVALID;
            this.importItems[index].statusValidateName = message;
          }
        }

        if (dataMessages.success.length === this.importItems.length) {
          this.makeToastSuccess('Tạo đơn hàng thành công');
          this.successCreateOrder = true;
          this.$emit('refresh', true);
        } else {
          this.makeToastFaile('Đơn hàng cập nhật thất bại');
          this.$emit('refresh', true);
        }
      }
    },
    sumAmountTotal(order) {
      if (order.errors.length > 0) return 0;

      const { discountType, orderItems, discountAmount, customerShipFee, shipFee } = order;
      const PERCENT_100 = 100;

      let totalPriceOrderItem = 0;
      let discountAmountTotal = 0;

      totalPriceOrderItem =
        orderItems &&
        orderItems.reduce(
          (sum, currentOrderItem) => sum + currentOrderItem.totalAmount,
          0
        );

      if (discountType === ENUM.AMOUNT_TYPE.PERCENT) {
        discountAmountTotal = totalPriceOrderItem - totalPriceOrderItem * (discountAmount / PERCENT_100);
      } else {
        discountAmountTotal = totalPriceOrderItem - discountAmount;
      }

      return discountAmountTotal + customerShipFee - shipFee;
    },
    rows() {
      return this.importItems.length;
    },
    openFile(name) {
      document.getElementById(name).click();
    },
    selectFile(event) {
      const files = event.target.files[0];
      const MAX_SIZE = 30 * 1024;

      if (!files) {
        this.textFile = '';
        return;
      }

      this.processBar = 0;
      if (!/(xls|xlsx|csv)$/.test(this.getExtension(files.name))) {
        this.clearFiles();
        return this.makeToastFaile(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls .xlsx hoặc csv'
        );
      } else if (files.size > MAX_SIZE) {
        this.clearFiles();
        return this.makeToastFaile('Chỉ nhận file nhỏ hơn 30kB');
      }

      this.importItems = [];
      this.file = files;
      this.textFile = files.name;
    },
    uploadFile() {
      if (!this.btnDisable) {
        this.btnDisable = true;
        this.processBar = 0;
        this.importItems = [];
        this.successCreateOrder = false;
        const submitButton = this.$refs['up_excel'];
        this.$refs['up_excel'].innerHTML = '';
        submitButton.classList.add(
          'spinner',
          'spinner-primary',
          'spinner-md',
          'spinner-center',
          'px-7'
        );
        let formData = new FormData();
        const file = this.file;
        const route = this.$route;

        let path = route.path.split('/');
        path = 'upload/' + path[path.length - 2];

        formData.append('path', path);
        formData.append('mode', route.params.mode);
        formData.append('file', file);

        const url = BASE_URL + 'orders/import-excel';

        axios
          .post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data;',
            },
            timeout: 10000 * 60,
          })
          .then((rs) => {
            this.processBar = 100;
            this.btnDisable = false;
            this.$nprogress.done();
            this.$refs['up_excel'].innerHTML =
              '<i class="fas fa-upload text-primary"></i>';
            submitButton.classList.remove(
              'spinner',
              'spinner-primary',
              'spinner-md',
              'spinner-center',
              'px-7'
            );

            if (rs.data.status) {
              this.importItems = [...rs.data.data].map(item => {
                const statusValidate = item.errors.length > 0 ? STATUS_VALIDATE.INVALID : STATUS_VALIDATE.READY;
                return { ...item, onLoading: false, statusValidate: statusValidate };
              });
            }
            this.makeToastSuccess('Đọc file thành công');
          })
          .catch(error => {
            setTimeout(() => {
              this.btnDisable = false;
              this.$refs['up_excel'].innerHTML =
                '<i class="fas fa-upload text-primary"></i>';
              this.$nprogress.done();
              submitButton.classList.remove(
                'spinner',
                'spinner-primary',
                'spinner-md',
                'spinner-center',
                'px-7'
              );
              if (error) {
                const { message } = error.response.data;
                this.makeToastFaile(message);
              } else {
                this.makeToastFaile(
                  'Vui lòng chọn lại file hoặc kiểm tra kết nối mạng'
                );
              }
            }, 500);
          });
      } else {
        this.makeToastFaile('File đang được tải lên ...');
      }
    },
    getExtension(fname) {
      const pos = fname.lastIndexOf('.');
      const strlen = fname.length;
      let extension = '';
      if (pos != -1 && strlen != pos + 1) {
        let ext = fname.split('.');
        let len = ext.length;
        extension = ext[len - 1].toLowerCase();
      } else {
        return this.makeToastFaile('File không đúng định dạng');
      }
      return extension;
    },
    onRemovedItem(orderCode) {
      const findIndexBy = (items, orderCode) => {
        return items.findIndex(item => item.orderCode === orderCode);
      };

      const index = findIndexBy(this.importItems, orderCode);
      if (index > -1) {
        this.importItems.splice(index, 1);
      }
    },
    clearFiles() {
      this.importItems = [];
      this.file = null;
      this.processBar = 0;
      this.$refs['file-input'].reset();
      this.textFile = '';
    },
    htmlGetStatusValidate(item, type) {
      let name = '';
      switch (item.statusValidate) {
        case STATUS_VALIDATE.READY: {
          name =
            type === 'class' ? 'far fa-clock text-primary' : 'Sẵn sàng tạo mới';
          break;
        }
        case STATUS_VALIDATE.VALID: {
          name =
            type === 'class' ? 'fas fa-check-circle text-success' : 'Sẵn sàng tạo mới';
          break;
        }
        default: {
          name =
            type === 'class'
              ? 'fas fa-times-circle text-danger'
              : 'Không hợp lệ';
          break;
        }
      }
      return name;
    },
    popupImportExcelCreateOrder() {
      this.$bvModal.show('import-excel-create-orders');
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
  },
};
</script>
<style lang="scss">
.order-import-excel {
  table.b-table[aria-busy='true'] {
    opacity: 0.6;
  }
}
#import-excel-create-orders {
  .modal-dialog.modal-xl {
    max-width: 95vw;
  }
}
</style>