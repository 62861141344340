var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[(_vm.configHtml.colsType === 0 ? false : true)?_c('b-col',{class:_vm.configHtml.classType,attrs:{"cols":_vm.configHtml.colsType}},[_c('b-form-select',{staticClass:"select-style",attrs:{"options":[
          {
            id: 1,
            name: 'Tìm sản phẩm',
          },
          {
            id: 2,
            name: 'Tìm sản phẩm imei',
          },
        ],"size":"sm","value-field":"id","text-field":"name","disabled":_vm.isDisabled},model:{value:(_vm.productSearch.searchType),callback:function ($$v) {_vm.$set(_vm.productSearch, "searchType", $$v)},expression:"productSearch.searchType"}})],1):_vm._e(),_c('b-col',{class:_vm.configHtml.classProduct,attrs:{"cols":_vm.configHtml.colsProduct}},[_c('treeselect',{attrs:{"load-options":_vm.loadOptionProducts,"multiple":false,"async":true,"placeholder":_vm.productSearch.placeHolder,"cache-options":false,"retryText":"Thử lại...","retryTitle":"Nhấp thử lại","searchPromptText":"Nhập để tìm kiếm...","loadingText":"Đang tải...","noResultsText":"Không có kết quả","openDirection":"bottom","always-open":_vm.isOpenProductSearch,"disabled":_vm.isDisabled},on:{"select":_vm.onSelectProduct,"search-change":_vm.onSearchChange},scopedSlots:_vm._u([{key:"option-label",fn:function({ node, labelClassName }){return _c('label',{class:labelClassName},[[_c('div',[_c('b',{staticClass:"text-primary"},[_vm._v("( "+_vm._s(_vm.convertPrice(node.raw.sellingPrice))+" )")]),_c('b',{class:node.raw.quantityInStock > 0
                    ? 'text-success'
                    : 'text-danger'},[_vm._v(" ( TCB:"+_vm._s(node.raw.quantityInStock)+" ) ")]),_vm._v(" "+_vm._s(node.raw.label)+" "),(
                  node.raw.imeiCode && node.raw.imeiCode.trim().length > 0
                )?_c('span',{staticClass:"text-warning"},[_vm._v(" - IMEI: "+_vm._s(node.raw.imeiCode)+" ")]):_vm._e()])]],2)}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }