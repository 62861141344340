import Swal from 'sweetalert2';

const swalMixin = {
  data() {
    return {
      swalTemplate: {
        success: {
          title: 'Thành công',
          text: 'Thao thác thực hiện thành công'
        },
        error: {
          title: 'Lỗi',
          text: 'Thao thác không thành công'
        },
        warning: {
          title: 'Cảnh báo',
          text: 'Cảnh báo thao tác'
        },
        info: {
          title: 'Thông tin',
          text: 'Thông tin'
        },
        question: {
          title: 'Không tồn tại',
          text: 'Không thành công'
        }
      },
      swalConfig: {
        title: 'Thông báo!',
        text: 'Cảnh báo',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#DD3333',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      },
      swalConfigError: {
        title: 'Không thể xoá!',
        icon: 'error',
        text: 'Lỗi',
        confirmButtonColor: '#DD3333',
        confirmButtonText: 'Trở về',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }
    };
  },
  methods: {
    popupSwalWarning({ title, text }) {
      return Swal.fire({
        ...this.swalConfig,
        title: title,
        text: text,
        icon: 'warning'
      });
    },
    popupSwalError({ title, text }) {
      return Swal.fire({
        ...this.swalConfigError,
        title: title,
        text: text,
        icon: 'error'
      });
    }
  }
};
export default swalMixin;
