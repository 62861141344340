<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      <div>
        <label v-if="bindingLabel">
          {{
            `Tiền ${
              bindingLabel === 'tiền mặt'
                ? bindingLabel.replace('tiền', '')
                : bindingLabel
            }`
          }}

          <span v-if="index !== 0">{{ index + 1 }}</span>
        </label>

        <div v-if="max > 0" class="float-right d-flex d-inline">
          <slot
            name="label-right"
            :item="item"
            :index="index"
            :check="checkAmountMul"
            :disabled="disabled"
          >
          </slot>
          <span v-if="index === 0">
            <i
              class="fa fa-plus-circle text-primary pt-1 cursor-pointer"
              @click="addMorePayment(paymentType)"
              v-show="false"
            ></i
          ></span>
          <span v-else>
            <i
              class="fa fa-minus-circle text-danger pt-1"
              style="cursor: pointer"
              @click="removeMorePayment(item)"
            ></i>
          </span>
        </div>
      </div>
      <b-input-group>
        <template #prepend>
          <slot name="bind-money" :index="index" :disabled="disabled">
            <b-button
              :disabled="true"
              style="width: 40px"
              variant="secondary"
              size="sm"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </b-button>
          </slot>
        </template>
        <BInputNumber
          v-model="item.paymentAmount"
          :disabled="disabled"
          :placeholder="`Nhập số tiền ${
            bindingLabel === 'tiền mặt'
              ? bindingLabel.replace('tiền', '')
              : bindingLabel
          }`"
        />
      </b-input-group>
      <div v-if="checkAmountMul(item)" class="mb-2 mt-2">
        <label v-if="bindingLabel"
          >{{ `Tài khoản ${bindingLabel}` }}
          <span v-if="index !== 0">{{ index + 1 }}</span></label
        >
        <div
          class="payment-status"
          v-if="item.paymentType === PAYMENT_TYPE.TRANSFER && item.viaQRCode"
        >
          <span
            v-if="item.status === PAYMENT_STATUS.SUCCESS"
            class="text-success float-right"
            >Đã thanh toán</span
          >
          <span v-else class="text-dark float-right"> Chưa thanh toán </span>
        </div>
        <Autosuggest
          :placeholder="`tài khoản ${bindingLabel}`"
          :model="item.paymentName"
          :suggestions="options"
          @change="onInputChange($event, index)"
          @select="onSelected($event, index)"
          :state="validate"
          :disabled="disabled"
        />
      </div>
      <div>
        <slot
          name="other"
          :item="item"
          :index="index"
          :check="checkAmountMul"
          :disabled="disabled"
        ></slot>

        <slot name="qrCode" :item="item" :disabled="disabled"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { unMaskPrice, makeToastFaile, makeToastSuccess } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { PAYMENT_STATUS } from '../../../utils/enum';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';
import { EventBus } from '@/core/services/event-bus';
import { PAYMENT_TYPE } from '@/utils/enum';

const LIMIT_FIELD = 5;
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    label: {
      type: String,
      default: null,
    },
    paymentType: {
      type: Number,
      required: true,
      default: null,
      validator: (value) => {
        return Object.values(PAYMENT_TYPE).includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    validate: {
      type: Boolean,
      default: true,
    },
    max: {
      type: Number,
      default: LIMIT_FIELD,
      validator: (value) => {
        const max = Number(value) || 0;
        return max <= LIMIT_FIELD ? true : false;
      },
    },
  },
  data() {
    return {
      PAYMENT_STATUS,
      PAYMENT_TYPE,
      defaultPayment: {
        paymentRefId: null,
        paymentRefCode: null,
        paymentName: null,
        paymentAmount: null,
        paymentCode: null,
        transactionId: null,
        status: null,
        accountBankNo: null,
      },
    };
  },
  components: {
    Autosuggest,
    BInputNumber,
  },
  mounted() {
    EventBus.$on(`add-payments-${this.paymentType}`, this.addMorePayment);
  },
  beforeDestroy() {
    EventBus.$off(`add-payments-${this.paymentType}`, this.addMorePayment);
  },
  watch: {},
  computed: {
    bindingLabel() {
      return this.label
        ? this.label.charAt(0).toLowerCase() + this.label.slice(1)
        : null;
    },
  },
  methods: {
    addMorePayment(paymentType) {
      const count = this.items.length;
      if (count > this.max) {
        makeToastFaile(`Thêm tối đa được ${this.max} trường`);
        return;
      }
      switch (paymentType) {
        case PAYMENT_TYPE.INSTALLMENT: {
          this.items.push({
            id: this.$uuid.v4(),
            ...this.defaultPayment,
            paymentType,
          });
          break;
        }
        case PAYMENT_TYPE.CREDIT: {
          this.items.push({
            id: this.$uuid.v4(),
            ...this.defaultPayment,
            creditCardCode: null,
            creditCardNo: null,
            payCreditFeeType: 1,
            creditCardFee: null,
            creditFeeAccountantName: null,
            paymentType,
          });
          break;
        }
        case PAYMENT_TYPE.TRANSFER: {
          this.items.push({
            id: this.$uuid.v4(),
            ...this.defaultPayment,
            transferReferenceCode: null,
            paymentType,
            viaQRCode: false,
          });
          break;
        }
        case PAYMENT_TYPE.REFUND: {
          this.items.push({
            id: this.$uuid.v4(),
            ...this.defaultPayment,
            transferReferenceCode: null,
            paymentType,
            paymentOptionType: null,
          });
          break;
        }
      }
      makeToastSuccess(`Đã thêm thanh toán`);
    },
    removeMorePayment(item) {
      const index = this.items.findIndex((ins) => ins.id === item.id);
      // Cant remove the first element
      if (index > 0) {
        this.items.splice(index, 1);
        makeToastSuccess(`Đã xoá thanh toán`);
      } else {
        makeToastFaile(`Thanh toán không tồn tại`);
      }
    },
    checkAmountMul(item) {
      const term = this.items.find((i) => i.id === item.id);

      if (term && unMaskPrice(term.paymentAmount) > 0) {
        return true;
      }
      return false;
    },
    onSelected(option, index) {
      option.index = index;
      this.$emit('select', option);
    },
    onInputChange(text, index) {
      this.$emit('change', {
        text,
        index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-payment {
  display: flex;
  justify-content: space-between;
}
.payment-status {
  display: inline;
}
</style>
