<template>
  <div class="order-step">
    <div>
      <div v-if="typeHeader === 'order-upsert'">
        <b-row>
          <b-col cols="2">
            <b class="mr-2">
              <span class="d-block">
                <span
                  v-html="tagHandler.roleIcon"
                  @click="handleChangeDepartment"
                  v-b-tooltip.left
                  :title="tagHandler.roleText"
                ></span>
                {{ tagHandler.roleTypeText }}</span>

              <span
                class="text-primary"
                v-if="action === 'update' && orderInfo.orderId"
              >
                #{{ orderInfo.orderId }}</span>
            </b>
          </b-col>
          <!--  <b-col cols="9" class="text-center d-flex" v-if="false">
            <b class="mr-2 justify-content-center align-self-center"
              >{{ tagHandler.roleText }} |</b
            >
            <vue-step-progress-indicator
              :steps="dataSteps"
              :active-step="activeStep"
              :is-reactive="true"
              :styles="styleSteps"
              :show-label="true"
              reactivity-type="all"
              @onStepChanged="onStepChanged"
              v-show="false"
            />
          </b-col> -->
          <!-- <b-col
            cols="11"
            class="d-flex text-right btn-config"
            style="justify-content: right"
          >
           <i class="fas fa-cog text-dark align-self-center  cursor-pointer"></i>

         <b-col cols="8" class="text-center"> </b-col>  </b-col>-->
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col
            md="6"
            class="d-flex"
          >
            <b class="mr-2">
              <span>
                <span
                  v-if="!disable"
                  v-html="tagHandler.roleIcon"
                  @click="handleChangeDepartment"
                  v-b-tooltip.left
                  :title="tagHandler.roleText"
                ></span>
                <span
                  v-else
                  v-html="tagHandler.roleIcon"
                  v-b-tooltip.left
                  :title="tagHandler.roleText"
                >
                </span>
                {{ tagHandler.roleTypeText }}</span>
            </b>
            |
            <b>
              <b-dropdown
                class="mx-2 dropdown-custom"
                size="sm"
              >
                <template #button-content>
                  <b>{{ companyName }}</b>
                </template>
                <b-dropdown-group header="Chọn doanh nghiệp">
                  <div
                    v-for="(company, index) in companies"
                    :key="index"
                  >
                    <b-dropdown-item-button @click="onSelectedCompany(company)">{{ company.name }}</b-dropdown-item-button>
                  </div>
                </b-dropdown-group>
              </b-dropdown>
            </b>
            <!-- <div>
            <b-badge href="#" variant="white" class="mr-1 mb-1"
              ><span class="text-warning">Mới (100)</span></b-badge
            >
            <b-badge href="#" variant="white" class="mr-1 mb-1"
              ><span class="text-primary">Đã xác nhận (100)</span></b-badge
            >
            <b-badge href="#" variant="white" class="mr-1 mb-1"
              ><span class="text-info">Đang theo dõi (100)</span></b-badge
            >
            <b-badge href="#" variant="white" class="mr-1 mb-1"
              ><span class="text-danger">Đã chuẩn bị (100)</span></b-badge
            >
            <b-badge href="#" variant="white" class="mr-1 mb-1"
              ><span class="text-success">Thành công (100)</span></b-badge
            >
            <b-badge href="#" variant="white" class="mr-1 mb-1"
              >Không thành công(100)</b-badge
            >
            <b-badge href="#" variant="white" class="mr-1">Cr Huỷ(100)</b-badge>
          </div> -->
          </b-col>
          <b-col
            cols="6"
            class="text-right"
          >
            <b-dropdown
              size="sm"
              class="mr-4"
              variant="warning"
              right
              v-if="checkPermissions(['ORDER_UPDATE'])"
              :disabled="countOrderSelected ? false : true"
            >
              <template slot="button-content">
                <span class="font-weight-bolder">
                  <span class="count-status badge badge-circle badge-white mr-1">{{ countOrderSelected }}</span>
                  Đã chọn
                </span>
              </template>
              <b-dropdown-item @click="showModalChangeStatus">
                <i class="fa-solid fa-arrows-repeat-1"></i> Đổi trạng thái
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              size="sm"
              class="mr-4"
              right
              v-if="checkPermissions(['ORDER_VIEW'])"
            >
              <template slot="button-content">
                <i
                  style="font-size: 1rem"
                  class="fas fa-cog"
                ></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item
                v-if="checkPermissions(['ORDER_EXPORT'])"
                @click="showModalExcel"
              >
                <i class="far fa-file-excel mr-1"></i>
                <span> Xuất Excel</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              size="sm"
              right
              split
              variant="primary"
              @click="routeCreateOrder"
              v-if="checkPermissions(['ORDER_INSERT'])"
            >
              <template v-slot:button-content>
                <div class="font-weight-bolder">
                  <span>
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-add-1"
                    ></i>Tạo
                    mới</span>
                </div>
              </template>
              <b-dropdown-item-button @click="popupCreateOrder"><i class="fas fa-angle-double-right mr-2 text-primary"></i>Tạo
                nhanh đơn hàng</b-dropdown-item-button>
              <b-dropdown-item-button @click="popupImportExcelOrder"><i class="far fa-file-excel mr-2 text-success"></i>Import
                excel</b-dropdown-item-button>
            </b-dropdown>
          </b-col>
        </b-row>
      </div>
    </div>

    <template v-if="checkPermissions(['ORDER_VIEW'])">
      <b-modal
        v-model="showExportExecelModel"
        hide-footer
        title="Xuất excel danh sách đơn hàng"
      >
        <b-row>
          <b-col>
            <b-form-group>
              <label class="font-weight-bolder">
                <span>Chọn cột cần xuất</span>
              </label>
              <b-form-radio-group
                v-model="selectedExportColumn"
                :options="exportColumnOptions"
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group>
              <label class="font-weight-bolder">
                <span>Danh sách cần xuất</span>
              </label>
              <b-form-radio-group
                v-model="selectedExportOrder"
                :options="exportOrderOptions"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-progress :max="100">
          <b-progress-bar
            :value="processBar"
            :label="`${processBar.toFixed(2)}%`"
          ></b-progress-bar>
        </b-progress>
        <b-row class="mt-5">
          <b-col>
            <b-button
              style="fontweight: 600; width: 95px"
              variant="primary"
              size="sm"
              @click="exportData"
            >Xuất dữ liệu</b-button>
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="closeExportExcelModel"
            >Hủy</b-button>
          </b-col>
        </b-row>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import VueStepProgressIndicator from 'vue-step-progress-indicator';
import {
  STEP_ROLE_TYPE,
  ORDER_STATUS_CR,
  ORDER_STATUS_SHOP,
} from '@/utils/constants';
import { checkPermissions } from '@/utils/saveDataToLocal';
import fileDownload from '@/utils/file-download';
import { makeToastFaile, cloneDeep } from '@/utils/common';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import { EventBus } from '@/core/services/event-bus';
import { getStartOfDay, getEndOfDay} from '@/utils/date';

const ROLE_TYPE = {
  CR: 1,
  SHOP: 2,
  DP: 3,
};
const JOB_TITLE_CR = {
  QL_CSKH: 15,
  CSKH: 16,
  CTV_CSKH: 17,
};
const JOB_TITLE_DP = {
  QL_DP: 20,
  DP: 23,
};
export default {
  props: [
    'entity',
    'userInfor',
    'disable',
    'apiParams',
    'typeHeader',
    'param',
    'type',
    'action',
  ],
  mixins: [],
  data() {
    return {
      fixed: false,
      styleSteps: {
        progress__wrapper: {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          margin: '0 0',
        },
        progress__block: {
          display: 'flex',
          alignItems: 'center',
        },
        progress__bridge: {
          backgroundColor: 'grey',
          height: '2px',
          flexGrow: '1',
          width: '20px',
        },
        progress__bubble: {
          margin: '0',
          padding: '0',
          lineHeight: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '20px',
          width: '20px',
          borderRadius: '100%',
          backgroundColor: 'transparent',
          border: '2px grey solid',
          textAlign: 'center',
        },
        progress__label: {
          margin: '0 0.8rem',
          fontWeight: 600,
        },
      },
      tagHandler: {
        roleIcon: '',
        roleType: null,
        roleText: '',
        roleTypeText: '',
      },
      dataSteps: [],
      activeStep: 1,
      processBar: 0,
      showExportExecelModel: false,
      countProcessBar: 0,
      selectedExportColumn: 1,
      exportColumnOptions: [{ text: 'Tất cả', value: 1 }],
      selectedExportOrder: 3,
      exportOrderOptions: [{ text: 'Tất cả các trang', value: 3 }],
      companies: [],
      companyName: 'VMG',
      selectedOrders: [],
      orderInfo: null,
      status: [],
      countOrderSelected: 0,
    };
  },
  components: {
    // VueStepProgressIndicator,
  },
  watch: {
    getOrderStatus: {
      handler(value) {
        this.countOrderSelected = value.length;
      },
      deep: true,
    },
    userInfor(value) {
      this.handleJobTitle(value.jobTitleId);
    },
    param(value) {
      this.selectedOrders = value;
      if (this.type === 'change-status') {
        this.showModalChangeStatus();
      }
    },
  },
  created() {
    switch (this.typeHeader) {
      case 'order-list': {
        this.fetchCompanies();
        break;
      }
      case 'order-upsert': {
        this.orderInfo = this.getOrderInfo;
        break;
      }
      default:
        break;
    }
    if (this.userInfor) {
      this.handleJobTitle(this.userInfor.jobTitleId, this.typeHeader);
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(['getOrderInfo', 'getOrderStatus']),
  },
  methods: {
    checkPermissions,
    handleJobTitle(value, typeHeader) {
      let roleType = null;

      switch (value) {
        case JOB_TITLE_CR.QL_CSKH:
        case JOB_TITLE_CR.CSKH:
        case JOB_TITLE_CR.CTV_CSKH:
          roleType = ROLE_TYPE.CR;
          break;
        case JOB_TITLE_DP.DP:
        case JOB_TITLE_DP.QL_DP:
          roleType = ROLE_TYPE.DP;
          break;
        default:
          roleType = ROLE_TYPE.SHOP;
          break;
      }
      if (typeHeader === 'order-upsert') {
        this.showTextDepartmentCenter(roleType);
      }
      this.showTextDepartmentLeft(roleType);
    },
    showTextDepartmentLeft(roleType) {
      const items = cloneDeep(STEP_ROLE_TYPE);
      let item = items.find((role) => role.roleType === roleType);
      if (!item) {
        item = {
          roleIcon: '',
          roleText: '',
          roleTypeText: '',
        };
      }
      const roleIcon = `<i class="${item.roleIcon || ''} cursor-pointer" ></i>`;
      this.tagHandler = item;
      this.tagHandler.roleIcon = roleIcon;
      this.tagHandler.roleType = roleType;
    },
    showTextDepartmentCenter(roleType) {
      let dataSteps = [];

      switch (roleType) {
        case ROLE_TYPE.CR: {
          const status = ORDER_STATUS_CR.reduce((result, item) => {
            if (item && !item.check) {
              result.push(item);
            }
            return result;
          }, []);
          const statusName = status.map(({ text }) => text);
          const statusValue = status.map(({ value }) => Number(value));

          const indexStatus = statusValue.findIndex(
            (status) => status === this.orderInfo.status,
          );

          dataSteps = [...statusName, ''];
          this.activeStep = indexStatus;
          this.status = status;
          break;
        }
        case ROLE_TYPE.SHOP: {
          const status = ORDER_STATUS_SHOP.reduce((result, item) => {
            if (item && !item.check) {
              result.push(item);
            }
            return result;
          }, []);

          const statusName = status.map(({ text }) => text);
          const statusValue = status.map(({ value }) => Number(value));

          const index = statusValue.findIndex(
            (status) => status === this.orderInfo.status,
          );

          dataSteps = [...statusName, ''];
          this.activeStep = index;

          this.status = status;
          break;
        }
        case ROLE_TYPE.DP:
          dataSteps = [
            'Đang đóng gói',
            'Đang giao',
            'Thành công',
            'Chuyển hoàn',
            'Không thành công',
          ];
          break;
        default:
          break;
      }
      this.dataSteps = dataSteps;
    },
    handleChangeDepartment() {
      let type =
        this.tagHandler.roleType === 3
          ? 1
          : Number(this.tagHandler.roleType) + 1;

      this.showTextDepartmentCenter(type);
    },
    showModalExcel() {
      this.processBar = 0;
      this.showExportExecelModel = true;
    },
    closeExportExcelModel() {
      this.showExportExecelModel = !this.showExportExecelModel;
    },
    async exportData() {
      this.processBar = 0;
      const params = {
        companyId: this.apiParams.companyId,
        fromDate: getStartOfDay(
          this.apiParams.fromDate,
        ),
        toDate: getEndOfDay(
          this.apiParams.toDate,
        ),
        orderSourceId: this.apiParams.orderSourceId,
        statusIds: this.apiParams.status.map((x) => x.value),
        reasonIds: this.apiParams.cancelStatus,
        orderType: this.apiParams.orderType,
        storeIds: this.apiParams.storeIds.map((x) => x.id),
        customerName: this.apiParams.customerNameSearch,
        customerPhone: this.apiParams.customerPhoneSearch,
        customernote: this.apiParams.customerNote,
        orderId: this.apiParams.orderId,
        saleId: this.apiParams.employeeId,
        saleNote: this.apiParams.saleNote,
        productSearch: this.apiParams.productSearch,
        state: 'current' || 'all',
        departmentId: this.apiParams.departmentId,
      };
      try {
        const response = await ApiService.query('orders/export-excel', {
          params,
          responseType: 'blob',
          onDownloadProgress: function (progressEvent) {
            this.countProcessBar++;
            if (
              progressEvent['srcElement'].response &&
              progressEvent['srcElement'].response.size
            ) {
              this.processBar = 100;
            } else {
              this.processBar += 5;
            }
          }.bind(this),
        });
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      } catch (error) {
        const responseObj = JSON.parse(await error.response.data.text());
        return makeToastFaile(responseObj.message || 'Lỗi');
      }
    },
    fetchCompanies: function () {
      const companyId = this.apiParams.companyId;
      if (!companyId) return;

      ApiService.get('/company').then((response) => {
        if (response.data.status) {
          const companies = response.data.data;
          companies.map((company) => {
            if (company.id === companyId) {
              this.companyName = company.name;
            }
          });
          this.companies = companies;
        }
      });
    },
    onSelectedCompany(company) {
      if (company) {
        this.companyName = company.name;
        this.$emit('update-company-component', company.id);
      }
    },
    showModalChangeStatus() {
      if (!this.countOrderSelected) {
        makeToastFaile('Chưa có đơn nào được chọn');
        return;
      }
      this.$emit('set-mode', 'changeStatus');
      EventBus.$emit('popup-common-order');
    },
    onStepChanged(step) {
      const statusBefore = this.orderInfo.status;
      const statusOrigin = this.status.find(
        (step) => Number(step.value) === statusBefore,
      );
      if (step < statusOrigin.position) {
        this.showAlert('Không chuyển trạng về trước đó');
      }
    },
    showAlert: function (text) {
      return Swal.fire({
        title: 'Không thực hiện được',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    },
    routeCreateOrder() {
      this.$router.push({
        name: 'add-order',
      });
    },
    popupCreateOrder() {
      this.$root.$emit('popup-create-order');
    },
    popupImportExcelOrder() {
      EventBus.$emit('popup-import-orders');
    },
  },
};
</script>

<style lang="scss">
.order-step {
  .progress__wrapper .progress__block:last-child {
    display: none !important;
  }
  .count-status {
    padding: 0.19rem 0.4rem;
  }
}
.dropdown-custom {
  .btn-sm,
  .btn-group-sm > .btn {
    padding: 1px 2px;
    background-color: transparent;
    border: none;
    border-radius: 3px;
  }
}
</style>
