<template>
  <div class="info-approval">
    <b-row>
      <b-col md="3">
        <b-form-group :label="'Ngày thanh toán'" :disabled="!editable">
          <date-picker
            placeholder="Chọn ngày thanh toán"
            class="form-control form-control-sm"
            :config="dpConfigs.date"
            v-model="mainModel.paymentDate"
          ></date-picker>
        </b-form-group>
        <b-form-group :label="'Loại chi'" :disabled="!editable">
          <b-form-select
            class="select-style"
            v-model="mainModel.type"
            :options="listType"
            size="sm"
            value-field="id"
            text-field="name"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group :label="'Chứng từ'" :disabled="!editable">
          <b-row>
            <b-col class="pr-0" cols="6">
              <b-form-select
                class="select-style"
                v-model="mainModel.entityType"
                :options="listEntityType"
                size="sm"
                value-field="id"
                text-field="name"
              >
              </b-form-select>
            </b-col>
            <b-col class="pl-0" cols="6">
              <b-form-input
                class="input-style text-right"
                size="sm"
                type="text"
                :value="mainModel.entityId"
                placeholder="ID chứng từ"
                :disabled="true"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group :label="'Số tiền'">
          <b-row>
            <b-col class="pr-0" cols="6">
              <b-form-select
                class="select-style"
                v-model="mainModel.paymentType"
                :options="listPaymentType"
                size="sm"
                value-field="id"
                text-field="name"
              >
              </b-form-select>
            </b-col>
            <b-col class="pl-0" cols="6">
              <b-form-input
                class="input-style text-right"
                size="sm"
                type="text"
                v-model="mainModel.amount"
                placeholder="Nhập số tiền"
                v-mask="currency"
                :disabled="!editable"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group :label="'VAT(%)'" v-if="false">
          <b-row>
            <b-col class="pr-0" cols="5">
              <b-form-input
                class="input-style text-right"
                size="sm"
                type="text"
                v-model="mainModel.vatAmount"
                v-mask="currency"
                placeholder="% VAT"
              ></b-form-input>
            </b-col>
            <b-col class="pl-0" cols="7">
              <b-form-input
                class="input-style text-right"
                size="sm"
                type="text"
                :value="finalVatAmount"
                v-mask="currency"
                placeholder="% VAT"
                :disabled="true"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group :label="'Điện thoại'" :disabled="!editable">
          <Autosuggest
            :model="mainModel.customerMobile"
            :suggestions="filteredOptionsCustomer"
            placeholder="số điện thoại"
            :limit="10"
            @select="onSelectCustomer"
            @change="onSearchChange"
          >
            <template #custom="{ suggestion }">
              <div>
                <div class="d-flex flex-column">
                  <span class="text-primary mb-1" v-if="suggestion.item.phoneNo"
                    >({{ suggestion.item.phoneNo }})</span
                  >
                  <span v-if="suggestion.item.fullName">{{
                    suggestion.item.fullName
                  }}</span>
                  <span v-else>{{ suggestion.item.message }}</span>
                </div>
              </div>
            </template>
          </Autosuggest>
        </b-form-group>
        <b-form-group :label="'Họ và tên:'" :disabled="!editable">
          <b-form-input
            class="input-style"
            size="sm"
            type="text"
            v-model="mainModel.customerName"
            placeholder="Tên khách hàng"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="'Tên ngân hàng nhận tiền:'">
          <b-form-input
            class="input-style"
            size="sm"
            type="text"
            v-model="mainModel.customerBankName"
            placeholder="Tên khách hàng"
            :disabled="!isEditable()"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="'STK nhận tiền:'">
          <b-form-input
            class="input-style"
            size="sm"
            type="text"
            v-model="mainModel.customerBankNo"
            placeholder="Tên khách hàng"
            :disabled="!isEditable()"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="'Tên người nhận:'">
          <b-form-input
            class="input-style"
            size="sm"
            type="text"
            v-model="mainModel.customerAccountName"
            placeholder="Tên người nhận"
            :disabled="!isEditable()"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <p class="text-center font-weight-bold">Quy trình duyệt</p>
        <b-row class="col-md-12 mb-1 m-0 p-0" v-if="!approvalConfig.id">
          <b-alert
            show
            variant="danger"
            style="background-color: #ffffff; border-color: #efefef"
            class="w-100"
          >
            <i class="fa fa-exclamation-triangle text-warning"></i>
            <strong class="text-danger">
              Loại chi chưa được cấu hình duyệt chi!</strong
            >
          </b-alert>
        </b-row>
        <div v-else>
          <p class="font-weight-bold">{{ approvalConfig.name }}</p>
          <p class="font-weight-bold">
            Số bước duyệt: {{ approvalConfig.numOfStep }}
          </p>
          <b-table :fields="fields" :items="approvalStepDetails" hover bordered>
            <template v-slot:cell(status)="row">
              <i
                v-bind:class="{
                  'fas fa-check text-success': row.item.status === 2,
                  'fas fa-minus-circle text-danger': row.item.status === 3,
                }"
              />
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
import timeUtils from '@/utils/date';
import {
  currencyMask,
  unMaskPrice,
  makeToastFaile,
  formatDate,
} from '@/utils/common';
import {
  TIME_TRIGGER,
  APPROVAL_TYPE,
  APPROVAL_PAYMENT_TYPE,
  APPROVAL_ENTITY_TYPES,
} from '@/utils/constants';
import { OPERATOR_TYPE, APPROVAL_STEP_STATUS } from '@/utils/enum';
import decounce from 'debounce';
import ApiService from '@/core/services/api.service';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { cloneDeep } from '../../../utils/common';

export default {
  props: ['mainModel', 'onSubmit'],
  data() {
    return {
      currency: currencyMask,
      dpConfigs: timeUtils.DP_CONFIG,
      editable: false,
      listType: APPROVAL_TYPE,
      filteredOptionsCustomer: [],
      optionsCustomer: [],
      approvalConfig: {
        id: null,
        name: '',
        numOfStep: 1,
        details: [],
      },
      fields: [
        {
          key: 'step',
          label: 'Bước',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'approvalOwner',
          label: 'Người duyệt',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '50%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'status',
          label: 'Đã duyệt',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '35%',
          },
          tdClass: 'text-center',
        },
      ],
      listEntityType: [],
      listPaymentType: [],
    };
  },
  components: {
    datePicker,
    Autosuggest,
  },
  watch: {
    onSubmit: {
      handler: function (newValue) {
        if (newValue) {
          const payload = {
            ...this.mainModel,
            amount: unMaskPrice(this.mainModel.amount),
            vatAmount: unMaskPrice(this.mainModel.vatAmount),
            paymentDate: formatDate(this.mainModel.paymentDate, 'yyyy-MM-DD'),
            approvalConfigId: this.approvalConfig.id,
            details: this.approvalStepDetails,
          };
          this.$emit('submit-data', payload);
        }
      },
    },
  },
  async mounted() {
    this.initOptions();
    await this.getApprovalConfigByType();
  },
  computed: {
    finalVatAmount() {
      if (!this.mainModel.amount) {
        return 0;
      }
      if (!unMaskPrice(this.mainModel.vatAmount)) {
        return this.mainModel.amount;
      }
      const amount = unMaskPrice(this.mainModel.amount);
      const vatAmount = unMaskPrice(this.mainModel.vatAmount);
      return Number(amount) + (Number(amount) * Number(vatAmount)) / 100;
    },
    approvalStepDetails() {
      const steps = [];
      const amount = unMaskPrice(this.mainModel.amount);
      this.approvalConfig.details.forEach((detail) => {
        if (
          detail.operator1 === OPERATOR_TYPE.NONE &&
          detail.operator2 === OPERATOR_TYPE.NONE
        ) {
          const step = this.setApproveStep(detail);
          steps.push(step);
        }
        if (
          detail.operator1 === OPERATOR_TYPE.LT &&
          detail.operator2 === OPERATOR_TYPE.NONE
        ) {
          if (detail.amount1 < amount) {
            const step = this.setApproveStep(detail);
            steps.push(step);
          }
        }
        if (
          detail.operator1 === OPERATOR_TYPE.LTE &&
          detail.operator2 === OPERATOR_TYPE.NONE
        ) {
          if (detail.amount1 <= amount) {
            const step = this.setApproveStep(detail);
            steps.push(step);
          }
        }
        if (
          detail.operator1 === OPERATOR_TYPE.NONE &&
          detail.operator2 === OPERATOR_TYPE.LT
        ) {
          if (amount < detail.amount2) {
            const step = this.setApproveStep(detail);
            steps.push(step);
          }
        }
        if (
          detail.operator1 === OPERATOR_TYPE.NONE &&
          detail.operator2 === OPERATOR_TYPE.LTE
        ) {
          if (amount <= detail.amount2) {
            const step = this.setApproveStep(detail);
            steps.push(step);
          }
        }
        if (
          detail.operator1 === OPERATOR_TYPE.LT &&
          detail.operator2 === OPERATOR_TYPE.LT
        ) {
          if (detail.amount1 < amount && amount < detail.amount2) {
            const step = this.setApproveStep(detail);
            steps.push(step);
          }
        }
        if (
          detail.operator1 === OPERATOR_TYPE.LT &&
          detail.operator2 === OPERATOR_TYPE.LTE
        ) {
          if (detail.amount1 < amount && amount <= detail.amount2) {
            const step = this.setApproveStep(detail);
            steps.push(step);
          }
        }
        if (
          detail.operator1 === OPERATOR_TYPE.LTE &&
          detail.operator2 === OPERATOR_TYPE.LT
        ) {
          if (detail.amount1 <= amount && amount < detail.amount2) {
            const step = this.setApproveStep(detail);
            steps.push(step);
          }
        }
        if (
          detail.operator1 === OPERATOR_TYPE.LTE &&
          detail.operator2 === OPERATOR_TYPE.LTE
        ) {
          if (detail.amount1 <= amount && amount <= detail.amount2) {
            const step = this.setApproveStep(detail);
            steps.push(step);
          }
        }
      });
      return steps;
    },
  },
  methods: {
    setApproveStep(approvalConfigDetail) {
      const modelDetail = this.mainModel.details.find(
        (detail) => detail.step === approvalConfigDetail.step,
      );
      const approvalOwnerNames = approvalConfigDetail.owners.map(
        (owner) => owner.name,
      );
      return {
        ...approvalConfigDetail,
        approvalOwner: approvalOwnerNames.join(','),
        status: modelDetail ? modelDetail.status : 0,
      };
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    onSelectCustomer(option) {
      if (option.item.id) {
        this.mainModel.customerId = option.item.id;
        this.mainModel.customerMobile = option.item.phoneNo;
        this.mainModel.customerName = option.item.fullName;
      } else {
        this.mainModel.customerId = null;
        this.mainModel.customerMobile = '';
        this.mainModel.customerName = '';
      }
    },
    onSearchChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.mainModel.customerMobile = searchQuery;
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer];
      this.debounceInput();
    },
    getListCustomer() {
      const text = this.mainModel.customerMobile
        ? this.mainModel.customerMobile.trim()
        : null;
      if (!text || text.length < 5) {
        return;
      }
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          this.optionsCustomer = data.data.data;
          if (this.optionsCustomer.length > 0) {
            this.filteredOptionsCustomer = [...this.optionsCustomer];
          } else {
            this.filteredOptionsCustomer = [
              { message: 'SĐT không có trong hệ thống' },
            ];
          }
        },
      );
    },
    async getApprovalConfigByType() {
      await ApiService.get(`v2/approval-configs/by-type/${this.mainModel.type}`)
        .then(({ data }) => {
          this.approvalConfig = data.data;
          this.mainModel.approvalConfigId = this.approvalConfig.id;
        })
        .catch((err) => {
          if (!err.response) {
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.approvalConfig.approvalConfigId = null;
        });
    },
    isEditable() {
      return this.mainModel.status === APPROVAL_STEP_STATUS.NEW;
    },
    initOptions() {
      this.listEntityType = cloneDeep(APPROVAL_ENTITY_TYPES);
      this.listPaymentType = cloneDeep(APPROVAL_PAYMENT_TYPE).filter(
        (paymentType) => paymentType.id,
      );
    },
  },
};
</script>

<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
</style>
