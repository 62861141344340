<template>
  <div class="mb-2">
    <div>
      <label> Cấu hình trả góp 0%</label>
      <Autosuggest
        :placeholder="`Chương trình trả góp`"
        :model="getInstallmentProgram.installmentProgramName"
        :suggestions="getInstallmentProgram.details"
        @change="onInputChange($event, index)"
        @select="onSelected($event, index)"
        :state="validate"
        :disabled="true"
      />

      <b-form-group class="my-2">
        <b-form-select
          :disabled="isDisabled(getInstallmentProgram, 'monthNo')"
          :options="getInstallmentProgram.monthNo"
          v-model="getInstallmentProgram.installmentProgramMonthNo"
          size="sm"
          value-field="id"
          text-field="name"
          class="select-style"
        >
          <template v-slot:first>
            <b-form-select-option :value="null"
              >Chọn kỳ hạn</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group class="my-2">
        <b-form-select
          :disabled="isDisabled(getInstallmentProgram, 'prepayPercent')"
          :options="getInstallmentProgram.prepayPercent"
          v-model="getInstallmentProgram.installmentProgramPrepayPercent"
          size="sm"
          value-field="id"
          text-field="name"
          class="select-style"
        >
          <template v-slot:first>
            <b-form-select-option :value="null"
              >Chọn phần trăm trả trước</b-form-select-option
            >
          </template>
        </b-form-select>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { mapGetters } from 'vuex';
const PAYMENT_TYPE = {
  INSTALLMENT: 4,
};
export default {
  props: {
    paymentType: {
      type: Number,
      required: true,
      default: null,
      validator: (value) => {
        return Object.values(PAYMENT_TYPE).includes(value);
      },
    },
    isInterestZero: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    validate: {
      type: Boolean,
      default: true,
    },
    options: [],
  },
  components: {
    Autosuggest,
  },
  computed: {
    ...mapGetters(['getInstallmentProgram']),
  },
  methods: {
    isDisabled(value, type) {
      switch (type) {
        case 'monthNo':
          return this.disabled || !value.monthNo.length;
        case 'prepayPercent':
          return this.disabled || !value.prepayPercent.length;
        default:
          break;
      }
    },
  },
};
</script>