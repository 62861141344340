<template>
  <div class="w-100 order-list-table">
    <div>
      <b-row>
        <b-col cols="9">
          <p class="mt-4 text-dark" style="font-weight: 500">
            Tổng số:
            {{ convertPrice(mainList.total) }}
          </p>
        </b-col>
        <b-col cols="3">
          <div>
            <b-pagination-nav
              pills
              size="sm"
              v-show="mainList.pages >= 2"
              :number-of-pages="mainList.pages"
              :link-gen="linkGen"
              align="right"
              v-model="currentPage"
              use-router
              @change="fetchMainData()"
              class="mt-3"
              style="border-radius: 5px"
            ></b-pagination-nav>
          </div>
        </b-col>
      </b-row>

      <table class="table table-bordered-list table-hover col-md-12">
        <thead>
          <tr>
            <th class="text-center header-table-color">
              <b-form-checkbox
                v-model="checkAllOrders"
                size="lg"
                @change="getOrderSelected('all')"
              ></b-form-checkbox>
            </th>
            <th class="text-center header-table-color">ID</th>
            <th class="text-center header-table-color" style="width: 15%">
              Khách hàng
            </th>
            <th class="text-center header-table-color" style="width: 20%">
              Sản phẩm
            </th>
            <th class="text-center header-table-color">Giá</th>
            <th class="text-center header-table-color">SL</th>
            <th class="text-center">Tổng tiền</th>
            <th class="text-center header-table-color">
              <i
                v-b-tooltip.hover
                title="Thanh toán"
                class="fa fa-money-check-alt icon-color"
              ></i>
            </th>
            <th class="text-center header-table-color" style="width: 15%">
              Ghi chú
            </th>
            <th class="text-center header-table-color">Trạng thái</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(order, i) in mainList.dataset">
            <tr
              :key="i"
              @mouseover="hoverRow"
              @mouseleave="outRow"
              :data="`row_${order.id}`"
            >
              <td
                colspan="11"
                style="padding: 5px !important; border-bottom: none !important"
              >
                <div class="d-flex" style="font-size: 0.87rem">
                  <span class="mr-4" v-if="order.orderType">
                    <div class="text-center">
                      <i
                        v-if="order.orderType === ENUM.TYPE_ORDER.SHOP"
                        class="fas fa-store icon-color text-warning"
                        v-b-tooltip.hover.bottom
                        title="Mua tại quầy"
                      ></i>
                      <i
                        v-else-if="order.orderType === ENUM.TYPE_ORDER.PREORDER"
                        class="fas fa-shopping-basket icon-color text-danger"
                        v-b-tooltip.hover.bottom
                        title="Đặt trước"
                      ></i>
                      <i
                        v-else-if="order.orderType === ENUM.TYPE_ORDER.SHIPPING"
                        class="fas fa-truck icon-color text-success"
                        v-b-tooltip.hover.bottom
                        title="Tự vận chuyển"
                      ></i>
                      <i
                        v-else-if="
                          order.orderType === ENUM.TYPE_ORDER.EX_SHIPPING
                        "
                        class="fas fa-box icon-color text-primary"
                        v-b-tooltip.hover.bottom
                        title="Hãng vận chuyển (Ahamove, Nhất tín ...)"
                      ></i>
                      <i
                        v-else-if="order.orderType === ENUM.TYPE_ORDER.WEBSITE"
                        class="fas fa-globe-asia text-dark"
                        v-b-tooltip.hover.bottom
                        title="Website"
                      ></i>
                    </div>
                  </span>
                  <span class="mr-4">
                    Ngày tạo:
                    <span class="text-primary">
                      {{
                        formatDate(order.createdAt, 'DD/MM/YYYY HH:mm')
                      }}</span
                    >
                  </span>
                  <span class="mr-4" v-if="order.createdAt !== order.updatedAt">
                    Cập nhật:
                    <span class="text-primary">
                      {{
                        formatDate(order.updatedAt, 'DD/MM/YYYY HH:mm')
                      }}</span
                    >
                  </span>
                  <span v-if="order.orderSourceName" class="mr-4">
                    <span class="mr-1">Nguồn:</span>
                    <span>{{ order.orderSourceName }}</span>
                  </span>
                  <span class="mr-4" v-if="order.partnerPaymentStatus">
                    <span class="mr-1">Thanh toán:</span>
                    <span v-bind:class="bindClassNamePayment(order)">
                      {{ order.partnerPaymentStatusName }}</span
                    >
                  </span>
                </div>
                <div class="d-flex" style="font-size: 0.87rem">
                  <span class="mr-4" v-if="order.createdByName">
                    <span class="mr-1">NVCR:</span>
                    <span>
                      {{ order.createdByName }}
                      <small v-if="order.departmentName"
                        >({{ order.departmentName }})</small
                      >
                    </span>
                  </span>
                  <span class="mr-4" v-if="order.saleStoreName">
                    <span class="mr-1">NVBH:</span>
                    <span>
                      {{ order.saleStoreName }}
                    </span>
                  </span>
                </div>
              </td>
            </tr>
            <template v-for="(orderItem, j) in order.orderItems">
              <tr
                :key="i + j + orderItem.orderItemId"
                @mouseover="hoverRow"
                @mouseleave="outRow"
                :data="`row_${order.id}`"
              >
                <td
                  :rowspan="order.orderItems.length"
                  v-if="j === 0 || order.show ? true : false"
                  class="tdCenter"
                >
                  <b-form-checkbox
                    size="lg"
                    v-model="order.selected"
                    @change="getOrderSelected('one')"
                    class="text-center ml-1"
                  />
                </td>
                <td
                  :rowspan="order.orderItems.length"
                  v-if="j === 0 || order.show ? true : false"
                  class="tdCenter"
                >
                  <div
                    class="orderCode text-primary font-weight-bold"
                    @click="popupEditOrder(order.id)"
                  >
                    {{ order.id }}
                  </div>
                  <!-- <div class="orderCode text-primary font-weight-bold">
                    <b-dropdown
                      class="dropdown-custom"
                      size="sm"
                      @click="popupEditOrder(order.id)"
                      split
                    >
                      <template #button-content>
                        <strong class="text-primary">{{ order.id }}</strong>
                      </template>
                      <b-dropdown-group>
                        <b-dropdown-item-button
                          @click="copyOrderInfo(order.id, 'mã đơn hàng')"
                        >
                          <i
                            class="fa fa-clone text-primary"
                            style="font-size: 1rem"
                          ></i>
                          Copy đơn hàng</b-dropdown-item-button
                        >
                        <b-dropdown-item-button
                          @click="popupEditOrder(order.id)"
                        >
                          <i
                            class="flaticon2-pen icon-color"
                            style="font-size: 1rem"
                          ></i>
                          Chi tiết đơn hàng</b-dropdown-item-button
                        >
                      </b-dropdown-group>
                    </b-dropdown>
                  </div> -->
                  <b v-if="order.storeName" class="mt-2 d-block">
                    {{ order.storeName }}
                  </b>
                </td>
                <td
                  :rowspan="order.orderItems.length"
                  v-if="j === 0 || order.show ? true : false"
                  class="tdCenter"
                >
                  <small
                    v-if="
                      [ENUM.CUSTOMER_TYPE.VIP, ENUM.CUSTOMER_TYPE.KOL].includes(
                        order.customerType,
                      )
                    "
                    style="color: #fd9d9d"
                    >({{ order.customerTypeName }})</small
                  >
                  <small v-else style="color: #a4a4a5">
                    ({{ order.customerTypeName }})
                  </small>
                  <p
                    class="orderCode mb-0"
                    @click="editUser(order)"
                    style="font-weight: 550"
                  >
                    {{ order.customerName }}
                  </p>
                  <p class="orderCode mb-0" @click="editUser(order)">
                    {{ order.customerMobile }}
                  </p>
                  <div
                    v-if="
                      order.customerAddress &&
                        order.customerAddress.trim().length > 0
                    "
                  >
                    <b>ĐC:</b>
                    {{ order.customerAddress }}
                  </div>
                </td>

                <td class="align-middle">
                  <p class="m-0">
                    <i
                      class="fas fa-gift ml-4 icon-color"
                      v-if="orderItem.orderItemType === ENUM.ITEM_TYPE.GIFT"
                    ></i>
                    <i
                      class="fas fa-tags ml-4 icon-color"
                      v-else-if="
                        orderItem.orderItemType === ENUM.ITEM_TYPE.ATTACH
                      "
                    ></i>
                    {{ orderItem.productName }}
                    <MerchantLabel :merchantId="orderItem.merchantId" />
                  </p>
                  <p
                    v-if="
                      orderItem.imeiCode && orderItem.imeiCode.trim().length > 0
                    "
                  >
                    <i class="text-primary" style="font-size: 12px"
                      >IMEI: {{ orderItem.imeiCode }}</i
                    >
                  </p>
                  <tr
                    v-show="
                      orderItem.productInCombos &&
                        orderItem.productInCombos.length > 0
                    "
                    v-for="(items, index) in orderItem.productInCombos"
                    :key="index"
                    style="border-top: 1px solid gray"
                  >
                    <td style="border: none">
                      <i
                        v-b-tooltip.hover
                        title="Sản phẩm"
                        class="fas fa-cube icon-color"
                      ></i>
                    </td>
                    <td style="border: none; width: -webkit-fill-available">
                      {{ items.productName }}({{ items.quantity }})
                    </td>
                  </tr>
                </td>

                <td class="text-right align-middle">
                  {{ convertPrice(orderItem.productPrice) }}
                  <p
                    v-b-tooltip.hover.bottom
                    title="Chiết khấu sản phẩm"
                    v-if="orderItem.discountAmount"
                  >
                    <i
                      class="fa fa-arrow-down icon-color"
                      style="font-size: 12px; text-align: center"
                    ></i
                    >:
                    <b class="text-warning">
                      {{ convertPrice(orderItem.discountAmount) }}</b
                    >
                  </p>
                </td>

                <td class="text-center align-middle">
                  {{ orderItem.quantity }}
                </td>

                <td
                  :rowspan="order.orderItems.length"
                  v-if="j === 0 || order.show ? true : false"
                  class="text-right align-middle"
                >
                  <p v-show="order.customerShipFee > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      title="Phí ship"
                      class="fas fa-shipping-fast icon-color"
                    ></i>
                    : {{ convertPrice(order.customerShipFee) }}
                  </p>
                  <p v-show="order.shipFee > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      title="Phí trả hãng vận chuyển"
                      class="fas fa-hand-holding-usd icon-color"
                    ></i>
                    : {{ convertPrice(order.shipFee) }}
                  </p>
                  <p v-show="order.conversionFee > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      title="Phí chuyển đổi"
                      class="fa-solid fa-money-bill-transfer icon-color"
                    ></i>
                    : {{ convertPrice(order.conversionFee) }}
                  </p>
                  <p v-show="order.discountAmount > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      title="Chiết khấu"
                      class="fa fa-arrow-down icon-color"
                    ></i>
                    : {{ convertPrice(order.discountAmount) }}
                    <span v-if="order.discountType === 2"> % </span>
                  </p>
                  <b style="color: #2e7d32">
                    {{
                      convertPrice(order.totalAmount + order.conversionFee)
                    }}</b
                  >
                </td>
                <td
                  :rowspan="order.orderItems.length"
                  v-if="j === 0 || order.show ? true : false"
                  class="text-right align-middle"
                >
                  <p v-show="order.depositAmount > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      title="Đặt cọc"
                      class="fa fa-money-check-alt icon-color"
                    ></i>
                    : {{ convertPrice(order.depositAmount) }}
                  </p>
                  <p v-show="order.subtractPointAmount > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      title="Tiêu điểm"
                      class="fas fa-adjust icon-color"
                    ></i>
                    : {{ convertPrice(order.subtractPointAmount) }}
                  </p>
                  <p v-show="order.transferAmount > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      title="Đã chuyển khoản"
                      class="fa fa-university icon-color"
                    ></i>
                    : {{ convertPrice(order.transferAmount) }}
                  </p>
                  <p v-show="order.creditAmount > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      title="Tiền quẹt thẻ"
                      class="fa fa-credit-card icon-color"
                    ></i>
                    : {{ convertPrice(order.creditAmount) }}
                  </p>
                  <p v-show="order.installedMoneyAmount > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      title="Trả góp"
                      class="fa fa-cube icon-color"
                    ></i>
                    : {{ convertPrice(order.installedMoneyAmount) }}
                  </p>
                  <p v-show="order.conversionFee > 0" class="border-0">
                    <i
                      v-b-tooltip.hover
                      title="Thanh toán phí chuyển đổi"
                      class="fa-solid fa-money-bill-transfer icon-color"
                    ></i>
                    : {{ convertPrice(order.conversionFee) }}
                  </p>
                  <span
                    v-b-tooltip.hover
                    v-show="order.totalAmountCustomerPay < 0"
                    title="Tiền thừa"
                    style="font-weight: 500"
                    class="border-0 text-success"
                    >{{ convertPrice(order.changeMoney) }}</span
                  >
                  <span
                    v-b-tooltip.hover
                    v-show="order.totalAmountCustomerPay > 0"
                    title="Phải thu khách"
                    style="font-weight: 500"
                    class="border-0 text-warning"
                    >{{ convertPrice(order.totalAmountCustomerPay) }}</span
                  >
                </td>

                <td
                  :rowspan="order.orderItems.length"
                  v-if="j === 0 || order.show ? true : false"
                  class="wrapword"
                >
                  <span
                    v-if="order.codeShip && order.codeShip.trim().length > 0"
                    class="d-block mb-1"
                  >
                    <b>Mã vận đơn:</b> {{ order.codeShip }}
                  </span>
                  <span
                    v-if="
                      order.couponCode && order.couponCode.trim().length > 0
                    "
                    class="d-block"
                  >
                    <b>Mã coupon:</b> {{ order.couponCode }}
                  </span>
                  <p
                    v-if="order.codeShip && order.codeShip.trim().length > 0"
                    class="dashed"
                  ></p>

                  <p
                    @click="handleShowNoteModel(order)"
                    style="cursor: pointer"
                    class="float-right"
                  >
                    <i class="fas fa-edit"></i>
                  </p>
                  <p v-if="order.customerNote" class="pre-line">
                    {{ order.customerNote }}
                  </p>
                  <p
                    v-if="order.customerNote && order.saleNote"
                    class="dashed"
                  ></p>
                  <p v-if="order.saleNote" class="pre-line">
                    {{ order.saleNote }}
                  </p>
                </td>

                <td
                  :rowspan="order.orderItems.length"
                  v-if="j === 0 || order.show ? true : false"
                  class="align-middle text-center"
                >
                  <span v-if="showCancelStatus(order)" class="d-block">
                    ({{ getStatusCancelName(order.cancelStatus) }})
                  </span>
                  <span
                    style="width: max-content"
                    v-text="ENUM_NAME.ORDER_STATUS_NAME[order.status] || ''"
                    v-bind:class="bindClassNameStatus(order)"
                  ></span>
                  <div
                    v-if="
                      order.status === ENUM.ORDER_STATUS.SUCCESS &&
                        order.billNumber
                    "
                    class="mt-2"
                  >
                    <span
                      style="color: darkgoldenrod"
                      v-text="order.billNumber"
                      @click="linkToOrder(order.billNumber)"
                      class="orderCode"
                    ></span>
                    <div class="mt-4">
                      <p v-if="order.billState === 'DELETED'">
                        <i
                          class="fa fa-exclamation-triangle text-danger"
                          v-b-tooltip.hover.bottom
                          title="Hoá đơn đã được xoá"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <p v-if="order.billState === 'NOAMOUNT'">
                        <i
                          class="fa fa-exclamation-triangle text-warning"
                          v-b-tooltip.hover.bottom
                          title="Hoá đơn không có doanh thu"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  </div>
                </td>

                <td
                  :rowspan="order.orderItems.length"
                  v-if="j === 0 || order.show ? true : false"
                  class="align-middle"
                >
                  <div class="d-flex justify-content-center">
                    <b-dropdown
                      size="sm"
                      id="dropdown-left"
                      no-caret
                      right
                      v-if="
                        checkPermissions([
                          'ORDER_VIEW',
                          'ORDER_INSERT',
                          'ORDER_UPDATE',
                          'ORDER_DELETE',
                          'BILL_INSERT',
                        ])
                      "
                    >
                      <template slot="button-content">
                        <i
                          style="font-size: 1rem; padding-right: 0px"
                          class="fa-solid fa-gear"
                        ></i>
                      </template>
                      <b-dropdown-item
                        @click="printOrder(order)"
                        v-if="checkPermissions(['ORDER_VIEW'])"
                        lazy
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem"
                            class="flaticon2-printer icon-color"
                          ></i>
                          &nbsp; In đơn hàng
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-show="showConvertToBill(order.status)"
                        @click="createBill(order)"
                        v-if="
                          checkPermissions(['BILL_INSERT']) &&
                            order.companyId === 3
                        "
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem"
                            class="flaticon2-add-1 icon-color"
                          ></i>
                          &nbsp; Tạo hóa đơn bán lẻ
                          <MerchantLabel :merchantId="orderItem.merchantId" />
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-show="showConvertToBill(order.status)"
                        @click="createWholeSaleInvoice(order)"
                        v-if="
                          checkPermissions(['BILL_INSERT']) &&
                            order.companyId === 3
                        "
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem"
                            class="flaticon2-add-1 icon-color"
                          ></i>
                          &nbsp; Tạo hóa đơn bán buôn
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="getOrderSelected('on-setting', order)"
                        v-if="checkPermissions(['ORDER_UPDATE'])"
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem"
                            class="fas fa-sync-alt icon-color"
                          ></i>
                          &nbsp; Đổi trạng thái
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="editItem(order)"
                        v-if="checkPermissions(['ORDER_UPDATE'])"
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem"
                            class="flaticon2-pen icon-color"
                          ></i>
                          &nbsp; Chỉnh sửa
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-show="canDeleteOrder"
                        @click="showDeleteAlert(order)"
                        v-if="
                          checkPermissions(['ORDER_DELETE']) &&
                            order.companyId === 3
                        "
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem; color: #d33"
                            class="flaticon2-rubbish-bin-delete-button"
                          ></i>
                          &nbsp; Xóa
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </td>
              </tr>
            </template>
          </template>
          <tr v-show="!onLoadingList">
            <td
              :colspan="5"
              class="align-middle text-right icon-color font-weight-bolder"
            >
              Tổng
            </td>

            <td class="align-middle text-right icon-color font-weight-bolder">
              {{ sumQuantity }}
            </td>
            <td class="align-middle text-right icon-color font-weight-bolder">
              <p class="mb-0">
                <i
                  v-b-tooltip.hover
                  title="Phí vận chuyển"
                  class="fa fa-truck header-table-color"
                  style="font-size: inherit"
                ></i>
                {{ convertPrice(sumShipFee) }}
              </p>
              <p class="mb-0">
                <i
                  v-b-tooltip.hover
                  title="Phí ship báo khách"
                  class="fas fa-user header-table-color"
                  style="font-size: inherit"
                ></i>
                {{ convertPrice(sumCustomerShipFee) }}
              </p>
            </td>
            <td class="align-middle text-right icon-color font-weight-bolder">
              <p class="mb-0">
                <i
                  v-b-tooltip.hover
                  title="Tổng chuyển khoản"
                  class="fas fa-university header-table-color"
                  style="font-size: inherit"
                ></i>
                {{ convertPrice(sumTransferAmount) }}
              </p>
              <p class="mb-0">
                <i
                  v-b-tooltip.hover
                  title="Tổng đặt cọc"
                  class="fas fa-user header-table-color"
                  style="font-size: inherit"
                ></i>
                {{ convertPrice(sumDepositAmount) }}
              </p>
              <p class="mb-0" v-b-tooltip.hover title="Tổng thu">
                {{ convertPrice(sumTotalAmount) }}
              </p>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <vcl-table
        v-if="onLoadingList"
        :speed="5"
        :animate="true"
        :columns="10"
      ></vcl-table>
      <b-row>
        <b-col lg="3" md="3" sm="12">
          <p class="mt-3 text-dark" style="font-weight: 500">
            Tổng số:
            {{ convertPrice(mainList.total) }}
          </p>
        </b-col>
        <b-col lg="9" md="9" sm="12">
          <b-pagination-nav
            pills
            v-show="mainList.pages >= 2"
            :number-of-pages="mainList.pages"
            :link-gen="linkGen"
            align="right"
            v-model="currentPage"
            use-router
            @change="fetchMainData()"
          ></b-pagination-nav>
        </b-col>
      </b-row>
    </div>

    <template v-if="checkPermissions(['ORDER_UPDATE'])">
      <b-modal
        v-model="showEditNoteModal"
        hide-footer
        :title="'Sửa ghi chú cho đơn hàng #' + orderNoteModel.id"
      >
        <b-row class="mb-5">
          <b-col>
            <label class="labelInput" for="inputOriginalPrice"
              >Ghi chú bán hàng</label
            >
            <b-form-textarea
              v-model="orderNoteModel.customerNote"
              placeholder="Nhập ghi chú..."
              rows="5"
              max-rows="6"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <label class="labelInput">Ghi chú nội bộ</label>
            <b-form-textarea
              v-model="orderNoteModel.saleNote"
              placeholder="Nhập ghi chú..."
              rows="5"
              max-rows="6"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <label class="labelInput">Ghi chú bảo hành</label>
            <b-form-textarea
              v-model="orderNoteModel.warrantyNote"
              placeholder="Nhập ghi chú bảo hành..."
              rows="5"
              max-rows="6"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="updateNote"
              >Lưu</b-button
            >
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="handleShowNoteModel"
              >Hủy</b-button
            >
          </b-col>
        </b-row>
      </b-modal>
    </template>
    <template v-if="checkPermissions(['ORDER_UPDATE'])">
      <b-modal
        size="xl"
        hide-footer
        :title="titleOrderFromModal"
        id="md-edit-order"
      >
        <template>
          <UsertOrder
            :fromModal="true"
            :orderIdFromModal="orderIdFromModal"
            v-on:refresh-order="refreshOrder"
          />
        </template>
      </b-modal>
    </template>
  </div>
</template>

<style scoped>
.orderCode:hover {
  text-decoration: underline;
  cursor: pointer;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

/* .table th {
padding: 0.6rem;
} */

.table td {
  /* text-align: center !important;
  vertical-align: middle; */
  padding: 0.6rem;
}

.tdItemWidth {
  width: 350px;
}
.tdCenter {
  vertical-align: middle;
}
.icon-color {
  color: #464e5f;
}
.header-table-color {
  color: rgb(24, 28, 50);
}

.table-bordered-list th {
  border: 0.5px solid #d2d4d6 !important;
  background-color: #e0e0e0;
}

.table-bordered-list td {
  border: 0.5px solid #f3f3f3;
  /* border: 0.5px solid #fdfbfb; */
  /* border: 0.5px solid #d5d5d5; */
  border-bottom: 0.5px solid #adadad;
}

.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
.dashed {
  border: 1px dashed #464e5f;
  border-style: none none dashed;
  color: #fff;
  background-color: #fff;
}

.wrapword {
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap; /* Chrome & Safari */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* CSS3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-word;
  white-space: normal;
}

.pre-line {
  white-space: pre-line;
}

.badge-dark-blue {
  background-color: #1861ad;
}
</style>
<style lang="scss">
.order-list-table {
  .btn.btn-secondary.dropdown-toggle:after {
    padding: 0px 0px 0px 5px;
    color: #3699ff;
  }
}

#md-edit-order {
  @media (min-width: 1200px) {
    .modal-dialog.modal-xl {
      max-width: 1200px;
    }
  }
  .modal-body {
    padding: 0px;
  }
}
</style>
<script>
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import _ from 'lodash';
import {
  formatDate,
  convertPrice,
  makeToastSuccess,
  makeToastFaile,
  checkGroupOrderStatus,
} from '../../../utils/common';
import jQuery from 'jquery';
import * as ENUM from '../../../utils/enum';
import * as ENUM_NAME from '../../../utils/enum-name';
import {
  TIME_OUT,
  ORDER_CANCEL_STATUS,
  ORDER_STATUS,
} from '../../../utils/constants';
import { checkPermissions } from '../../../utils/saveDataToLocal';
import UsertOrder from '@/view/pages/orders/UpsertOrder.vue';
import { mapGetters } from 'vuex';
import { SET_ORDER_STATUS_SELECTED } from '@/core/services/store/modules/order/order-seleted-status.module.js';
import { EventBus } from '@/core/services/event-bus';
import MerchantLabel from '../../components/MerchantLabel.vue';

export default {
  props: ['paramSearch'],
  components: {
    VclTable,
    UsertOrder,
    MerchantLabel,
  },
  data() {
    return {
      ENUM,
      ENUM_NAME,
      ORDER_STATUS,
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      onLoadingList: false,
      currentPage: 1,
      sumShipFee: 0,
      sumCustomerShipFee: 0,
      sumTransferAmount: 0,
      sumDepositAmount: 0,
      sumTotalAmount: 0,
      sumQuantity: 0,
      checkAllOrders: false,
      optionCancelStatus: ORDER_CANCEL_STATUS,
      apiParams: null,
      showEditNoteModal: false,
      orderNoteModel: {
        id: null,
        customerNote: null,
        saleNote: null,
        warrantyNote: null,
      },
      hoverTable: {
        dataId: null,
      },
      inActiveEnter: false,
      orderIdFromModal: null,
      titleOrderFromModal: '',
      customerInfo: {
        customerName: null,
        customerPhone: null,
        customerId: null,
      },
    };
  },
  created() {
    this.apiParams = this.paramSearch;
  },
  mounted() {
    this.fetchMainData();
    this.$root.$on('popup-create-order', this.popupCreateOrder);
    EventBus.$on('re-seletect-order', this.reSelectOrders);
  },
  beforeDestroy() {
    this.$root.$off('popup-create-order', this.popupCreateOrder);
  },
  watch: {
    paramSearch: function(params) {
      this.apiParams = params;
      this.fetchMainData();
    },
  },
  methods: {
    formatDate,
    convertPrice,
    checkPermissions,
    hoverRow: function(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      if (this.hoverTable.dataId !== dataId) {
        const rows = jQuery(`[data=${dataId}]`);
        rows.addClass('tr-rowspan-hover');
        this.hoverTable.dataId = dataId;
      }
    },
    outRow: function(evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
      this.hoverTable.dataId = null;
    },
    handleShowNoteModel(item) {
      this.showEditNoteModal = !this.showEditNoteModal;
      this.orderNoteModel = {
        id: item.id,
        customerNote: item.customerNote,
        saleNote: item.saleNote,
        warrantyNote: item.warrantyNote,
      };
    },
    showConvertToBill(status) {
      return checkGroupOrderStatus().validToBill(status);
    },
    createWholeSaleInvoice: async function(item) {
      this.$router.push({
        name: 'add-wholesale-invoice',
        query: { fromOrderId: item.id },
      });
    },
    createBill: function(item) {
      this.$router.push({
        name: 'add-bill',
        query: { fromOrderId: item.id },
      });
    },
    printOrder: function(item) {
      const routeData = this.$router.resolve({
        name: 'print-order',
        query: { id: item.id },
      });
      window.open(routeData.href, '_blank');
    },
    updateNote: function() {
      ApiService.put('orders/update-note', this.orderNoteModel)
        .then((response) => {
          if (response.data.status === 1) {
            this.showEditNoteModal = !this.showEditNoteModal;
            this.fetchMainData();
            makeToastSuccess(response.data.message);
          } else {
            makeToastFaile(response.data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    fetchMainData: function() {
      this.onLoadingList = true;
      // get params
      const params = this.apiParams;

      this.currentPage = this.$route.query.page || 1;
      params.page = this.currentPage;
      this.$store.dispatch(SET_ORDER_STATUS_SELECTED, []);
      ApiService.query(
        'orders',
        { params },
        {
          timeout: TIME_OUT,
        },
      )
        .then((response) => {
          const data = response.data.data;
          this.mainList.pages = data.pages || 1;
          this.mainList.total = data.total;
          this.orders = [];
          this.sumQuantity = 0;
          this.sumShipFee = 0;
          this.sumCustomerShipFee = 0;
          this.sumTransferAmount = 0;
          this.sumDepositAmount = 0;
          this.sumTotalAmount = 0;
          const dataset = [];
          data.dataset.map((item) => {
            const order = {
              ...item,
              groupSize: item.orderItems.length,
              show: item.orderItems.length <= 0 ? true : false,
              selected: false,
            };
            //  tiền thừa cho khách
            order.changeMoney =
              item.totalAmountCustomerPay < 0
                ? item.totalAmountCustomerPay * -1
                : item.totalAmountCustomerPay;

            this.sumQuantity = order.orderItems.reduce((acc, cur) => {
              return acc + cur.quantity;
            }, this.sumQuantity);

            // caculate
            this.canDeleteOrder = item.canDeleteOrder;
            this.sumShipFee += order.shipFee;
            this.sumCustomerShipFee += order.customerShipFee;
            this.sumTransferAmount += order.transferAmount;
            this.sumDepositAmount += order.depositAmount;
            this.sumTotalAmount += order.totalAmount;
            dataset.push(order);
          });
          this.mainList.dataset = dataset;
          this.onLoadingList = false;
          this.$nprogress.done();
        })
        .catch((error) => {
          this.onLoadingList = false;
          this.$nprogress.done();
          if (error.response) {
            makeToastFaile(
              error.response.data ? error.response.data.message : 'Lỗi',
            );
          } else {
            makeToastFaile(error.message || 'Lỗi');
          }
        });
    },
    getOrderSelected: async function(prop, item) {
      switch (prop) {
        case 'all':
          this.mainList.dataset.map((order) => {
            order.selected = this.checkAllOrders;
            return order;
          });
          break;
        case 'one':
          if (this.checkAllOrders === true) {
            this.checkAllOrders = false;
          } else {
            const count = this.mainList.dataset.filter(
              (order) => order.selected,
            );
            if (count === 10) {
              this.checkAllOrders = true;
            }
          }
          break;
        case 'on-setting':
          {
            const index = this.mainList.dataset.findIndex(
              (order) => order.id === item.id,
            );

            if (index > -1) {
              this.mainList.dataset[index].selected = true;
            }
          }
          break;
      }
      const selectedOrders = this.mainList.dataset.filter(
        (order) => order.selected,
      );

      await this.$store.dispatch(SET_ORDER_STATUS_SELECTED, selectedOrders);

      if (prop === 'on-setting') {
        EventBus.$emit('popup-common-order');
      }
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0,
      );
    },
    linkGen: (pageNum) => {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
    linkToOrder: function(orderId) {
      const { href } = this.$router.resolve({
        name: 'update-bill',
        query: { id: orderId },
      });
      window.open(href, '_blank');
    },
    editItem: function(item) {
      this.$router.push({
        name: 'edit-order',
        params: { id: item.id },
      });
    },
    editUser: function(item) {
      this.$router.push({
        name: 'update-customer',
        query: { id: item.customerId, phoneNo: item.customerMobile },
      });
    },
    getStatusCancelName(id) {
      const cancelStt = this.optionCancelStatus.find((x) => x.value == id);
      if (cancelStt) return cancelStt.text;
      return 'Chưa có trạng thái lý do';
    },
    showCancelStatus(order) {
      return order.cancelStatus && [7, 8, 11].includes(Number(order.status));
    },
    bindClassNameStatus(order) {
      let className = '';
      switch (order.status) {
        case ENUM.ORDER_STATUS.NEW: {
          className = 'badge badge-warning text-white';
          break;
        }
        case ENUM.ORDER_STATUS.CONFIRMING: {
          className = 'badge badge-light';
          break;
        }
        case ENUM.ORDER_STATUS.CONFIRMED: {
          className = 'badge badge-primary text-white';
          break;
        }
        case ENUM.ORDER_STATUS.AGREE_DEPOSIT: {
          className = 'badge badge-dark-blue text-white';
          break;
        }
        case ENUM.ORDER_STATUS.KEEP_DEPOSIT: //IP14
        case ENUM.ORDER_STATUS.DELIVERY: {
          className = 'badge badge-warning';
          break;
        }
        case ENUM.ORDER_STATUS.TRACKING:
        case ENUM.ORDER_STATUS.PACKING: {
          className = 'badge badge-info';
          break;
        }
        case ENUM.ORDER_STATUS.SUCCESS: {
          className = 'badge badge-success';
          break;
        }
        case ENUM.ORDER_STATUS.UN_SUCCESS:
        case ENUM.ORDER_STATUS.SYSTEM_CANCEL:
        case ENUM.ORDER_STATUS.CR_CANCEL: {
          className = 'badge badge-dark';
          break;
        }
        case ENUM.ORDER_STATUS.REFUNDED:
        case ENUM.ORDER_STATUS.DEPOSIT: {
          className = 'badge badge-danger';
          break;
        }
        case ENUM.ORDER_STATUS.NO_CATCHED_PHONE: {
          className = 'badge badge-secondary';
          break;
        }
        case ENUM.ORDER_STATUS.PREPARED: {
          className = 'badge badge-danger text-dark';
          break;
        }
      }
      return className;
    },
    bindClassNamePayment(order) {
      let className = '';
      switch (order.partnerPaymentStatus) {
        case ENUM.PARTNER_PAYMENT.SUCCESS: {
          className = 'text-success';
          break;
        }
        case ENUM.PARTNER_PAYMENT.FAIL: {
          className = 'text-danger';
          break;
        }
        case ENUM.PARTNER_PAYMENT.AGREE_DEPOSIT: {
          className = 'text-primary';
          break;
        }
        case ENUM.PARTNER_PAYMENT.DEPOSIT: {
          className = 'text-warning';
          break;
        }
      }
      return className;
    },
    popupEditOrder(orderId) {
      this.orderIdFromModal = orderId;
      this.titleOrderFromModal = 'Chỉnh sửa đơn hàng';
      this.$bvModal.show('md-edit-order');
    },
    popupCreateOrder() {
      this.orderIdFromModal = null;
      this.titleOrderFromModal = 'Thêm mới đơn hàng';
      this.$bvModal.show('md-edit-order');
    },
    refreshOrder() {
      this.fetchMainData();
      this.$bvModal.hide('md-edit-order');
    },
    reSelectOrders(orders) {
      if (!orders) {
        return;
      }
      const orderIds = orders.map((item) => item.id);

      this.mainList.dataset.forEach((item) => {
        if (orderIds.includes(item.id)) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
    },
  },
  computed: {
    ...mapGetters(['getOrderStatus']),
    sumQty() {
      let sum = 0;
      _.map(this.mainList.dataset, (item) => {
        const tmpSum = _.sumBy(item.billItems, 'quantity');
        sum += tmpSum;
      });
      return sum;
    },
  },
};
</script>
