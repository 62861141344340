<template>
  <b-modal
    :ref="refModal"
    :id="refModal"
    size="xl"
    hide-footer
    header-class="p-0"
    :static="false"
  >
    <template #modal-header="{ close }">
      <b-nav tabs fill>
        <b-nav-item active>Chương trình trả góp</b-nav-item>
        <b-nav-item v-if="false">Chuyển đổi trả góp</b-nav-item>
      </b-nav>
      <b-button size="sm" class="close mr-4" @click="close()" aria-label="Close"
        >&times;
      </b-button>
    </template>
    <div class="mb-4 nav-child">
      <b-card no-body>
        <div class="p-4">
          <div class="d-flex d-inline">
            <div
              class="mr-2 mb-1"
              v-for="(program, index) in installmentPrograms"
              :key="index"
            >
              <b-badge
                :variant="
                  choiceProgram.id === program.id ? 'primary' : 'secondary'
                "
                href="javascript:void(0)"
                @click="onClickTab(index, program.id)"
                >{{ program.name }}</b-badge
              >
            </div>
          </div>
          <b-row class="mt-2">
            <b-col cols="4">
              <b-form-input
                class="input-style"
                size="sm"
                placeholder="Tên sản phẩm"
                autocomplete="off"
                v-model="filterParams.productInfor"
              ></b-form-input>
            </b-col>
            <b-col cols="8"> </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="12">
              <b>Sản phẩm áp dụng chương trình:</b>
              <b-table
                hover
                bordered
                show-empty
                :emptyText="'Không tìm thấy kết quả!'"
                :empty-filtered-text="'Không tìm thấy kết quả!'"
                :items="choiceProgram.products"
                :fields="fieldsProductsProgram"
                :current-page="choiceProgram.currentPage"
                :per-page="10"
                :busy.sync="isBusy"
                :filter="filterParams.productInfor"
              >
                <template #table-busy>
                  <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(barCode)="data">
                  <span class="d-block">{{ data.item.barCode }}</span>
                  <span class="d-block">{{ data.item.productCode }}</span>
                </template>
                <template #cell(productName)="data">
                  <b>{{ data.item.productName }}</b>
                </template>
                <template #cell(listedPrice)="data">
                  <b>{{ convertPrice(data.item.sellingPrice) }}</b>
                </template>
              </b-table>
              <b-pagination
                pills
                align="right"
                size="sm"
                v-model="choiceProgram.currentPage"
                :total-rows="
                  choiceProgram.products ? choiceProgram.products.length : 0
                "
                :per-page="10"
              ></b-pagination>
            </b-col>
            <b-col md="12" class="mb-2">
              <b class="mr-1">Danh mục áp dụng chương trình</b>
              <b-badge
                variant="secondary"
                class="mr-1 mb-2"
                v-for="(category, index) in choiceProgram.categories || []"
                :key="index"
              >
                {{ category.name }}
              </b-badge>
            </b-col>
            <b-col md="12">
              <b-overlay opacity="0.3" rounded="sm" blur="2px" :show="isBusy">
                <b-row>
                  <b-col cols="8">
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Tên chương trình"
                          label-class="font-weight-bold p-1"
                        >
                          <b-form-input
                            class="input-style"
                            size="sm"
                            placeholder="Tên chương trình"
                            :value="choiceProgram.name"
                            disabled
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Mã chương trình"
                          label-class="font-weight-bold p-1"
                        >
                          <b-form-input
                            class="input-style"
                            size="sm"
                            placeholder="Mã chương trình"
                            :value="choiceProgram.code"
                            disabled
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Ngày bắt đầu"
                          label-class="font-weight-bold p-1"
                        >
                          <b-form-input
                            class="input-style"
                            size="sm"
                            placeholder="Ngày bắt đầu"
                            :value="choiceProgram.startDate"
                            disabled
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Ngày kết thúc"
                          label-class="font-weight-bold p-1"
                        >
                          <b-form-input
                            class="input-style"
                            size="sm"
                            placeholder="Ngày kết thúc"
                            :value="choiceProgram.endDate"
                            disabled
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Người tạo"
                          label-class="font-weight-bold p-1"
                        >
                          <b-form-input
                            class="input-style"
                            size="sm"
                            placeholder="Người tạo"
                            :value="choiceProgram.createdByName"
                            disabled
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Trạng thái"
                          label-class="font-weight-bold p-1"
                        >
                          <b-form-checkbox
                            class="mt-2"
                            switch
                            v-model="choiceProgram.status"
                            disabled
                            >{{ choiceProgram.statusName }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Mô tả"
                          label-class="font-weight-bold p-1"
                        >
                          <b-form-textarea
                            :value="choiceProgram.description"
                            placeholder="Mô tả"
                            rows="3"
                            max-rows="6"
                            disabled
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="4">
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Kì hạn"
                          label-class="font-weight-bold p-1"
                        >
                          <div>
                            <b-badge
                              variant="secondary"
                              class="mr-1 mb-2"
                              v-for="(month, index) in choiceProgram.monthNo ||
                              []"
                              :key="index"
                            >
                              {{ month + ' Tháng' }}
                            </b-badge>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Nhà trả góp"
                          label-class="font-weight-bold p-1"
                        >
                          <div class="mt-1">
                            <b-badge
                              variant="secondary"
                              class="mr-1 mb-2"
                              v-for="(
                                intallment, index
                              ) in choiceProgram.details || []"
                              :key="index"
                            >
                              {{ intallment.intallmentName }}
                            </b-badge>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-overlay>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <!-- <b-tabs>
        <b-tab title="Chương trình" active>
          <b-card-text>
            
          </b-card-text>
        </b-tab>
        <b-tab title="Chuyển đổi trả góp" v-if="false">
          <b-card-text>
            <small class="text-danger"
              >* Chương trình trả góp 0% chỉ được chọn duy nhất 1 sản phẩm không
              bao gồm sản phẩm quà tặng</small
            >
            <b-table
              striped
              hover
              bordered
              :items="orderItems"
              :fields="fieldsProductsItems"
            >
              <template #cell(btnRadioSelected)="data">
                <input
                  type="radio"
                  v-model="data.item.btnRadioSelected"
                  @click="onClickRadioButtonSelected(data.item)"
                />
              </template>

              <template #cell(productName)="data">
                <b>{{ data.item.productName }}</b>
              </template>
              <template #cell(sellingPrice)="data">
                <del>{{ convertPrice(data.item.sellingPrice) }}</del>
                ->
                <span class="text-danger">{{
                  convertPrice(data.item.listedPrice)
                }}</span>
              </template>
              <template #cell(programs)="data">
                <ul
                  v-for="(program, index) in data.item.programs"
                  :key="index"
                  style="list-style-type: none"
                >
                  <li>{{ program.name }}</li>
                </ul>
              </template>
            </b-table>
          </b-card-text>
        </b-tab>
      </b-tabs> -->
    </div>
    <div class="mb-4 nav-child">
      <b-card no-body>
        <div class="p-4">Trung</div>
      </b-card>
    </div>

    <b-overlay
      :show="isProcessing"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
      v-if="paymentType"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner variant="light" small type="grow"></b-spinner>
        </div>
      </template>
      <b-button variant="primary" size="sm" @click.once="onAddPayments">
        <strong>Chuyển đổi</strong>
      </b-button>
    </b-overlay>
    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="$bvModal.hide(refModal)"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import { EventBus } from '@/core/services/event-bus';
import { cloneDeep, convertPrice } from '../../../utils/common';
import { mapGetters } from 'vuex';

export default {
  props: {},
  mixins: [validationMixin],
  data() {
    return {
      refModal: 'modal-installment-common',
      titleExcel: 'Trả góp 0%',
      isProcessing: false,
      installmentPrograms: [],
      installmentProducts: [],
      paymentType: null,
      fieldsProductsProgram: [],
      fieldsProductsItems: [],
      orderItems: [],
      choiceProgram: {},
      isBusy: true,
      filterParams: {
        productInfor: null,
      },
    };
  },
  components: {},
  computed: {
    ...mapGetters(['getProducts']),
  },
  created() {
    this.fetchInstallmentPrograms();
    this.initialData();
    this.fetchProgramByProductIds();
  },
  mounted() {
    EventBus.$on('popup-insterest-program', this.shownInterestProgramModal);
  },
  beforeDestroy() {
    this.setEventNavItem('removeEventListener');
    EventBus.$off('popup-insterest-program', this.shownInterestProgramModal);
  },
  watch: {
    $props: {
      handler() {},
      deep: true,
      immediate: true,
    },
  },
  validations: {},
  methods: {
    convertPrice,
    initialData() {
      this.fieldsProductsItems = this.getFieldProductItems();
    },
    setUpTabModal() {
      // set modal static: true

      this.setIdNavChild();

      this.setEventNavItem('addEventListener');

      this.setDefaultTab();
    },
    setIdNavChild() {
      const navChilds = document.querySelectorAll('.nav-child');
      if (navChilds.length) {
        navChilds.forEach((navChild, index) => {
          navChild.setAttribute('id', 'interest-' + index);
        });
      }
    },
    setEventNavItem(event) {
      const navItems = document.querySelectorAll('.nav-item');
      navItems.forEach((navItem, index) => {
        navItem[event]('click', (event) => {
          this.removeClassNavChilds();
          const navChildChoose = document.getElementById('interest-' + index);
          navChildChoose.classList.add('d-block');
          this.removeClassNavLinks();

          const navLink = navItem.querySelector('.nav-link');
          event.preventDefault();
          navLink.classList.add('active');
        });
      });
    },
    removeClassNavLinks() {
      const navLinks = document.querySelectorAll('.nav-item .nav-link');
      navLinks.forEach((link) => {
        link.classList.remove('active');
      });
    },
    removeClassNavChilds() {
      const navChilds = document.querySelectorAll('.nav-child');
      navChilds.forEach((child) => {
        if (
          child.classList.contains('d-block') ||
          child.classList.contains('d-none')
        ) {
          child.classList.remove('d-block', 'd-none');
        }
        child.classList.add('d-none');
      });
    },
    setDefaultTab() {
      const navChildChoose = document.getElementById('interest-0');
      if (navChildChoose) {
        navChildChoose.classList.add('d-block');
      }
    },
    getFieldProductItems() {
      return [
        {
          key: 'btnRadioSelected',
          label: '',
          thClass: 'text-center',
          tdClass: 'align-middle text-center',
          thStyle: {
            width: '5%',
          },
          tdStyle: {
            width: '5%',
          },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thClass: 'text-center',
          tdClass: 'align-middle',
          thStyle: {
            width: '25%',
          },
          tdStyle: {
            width: '30%',
          },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán / Giá niêm yết',
          thClass: 'text-center',
          tdClass: 'align-middle',
          thStyle: {
            width: '25%',
          },
          tdStyle: {
            width: '30%',
          },
        },
        {
          key: 'discountAmount',
          label: 'Giảm giá',
          thClass: 'text-center',
          tdClass: 'align-middle',
          thStyle: {
            width: '25%',
          },
          tdStyle: {
            width: '30%',
          },
        },
        {
          key: 'programs',
          label: 'Chương trình',
          thClass: 'text-center',
          tdClass: 'align-middle',
          thStyle: {
            width: '25%',
          },
          tdStyle: {
            width: '30%',
          },
        },
      ];
    },
    getFieldProductPrograms() {
      return [
        {
          key: 'barCode',
          label: 'Mã sản phẩm',
          thClass: 'text-center',
          tdClass: 'align-middle',
          thStyle: {
            width: '15%',
          },
          tdStyle: {
            width: '15%',
          },
          sortable: true,
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thClass: 'text-center',
          tdClass: 'align-middle',
          thStyle: {
            width: '25%',
          },
          tdStyle: {
            width: '30%',
          },
          sortable: true,
        },
        {
          key: 'listedPrice',
          label: 'Giá bán',
          thClass: 'text-center',
          tdClass: 'align-middle text-right',
          tdStyle: {
            width: '15%',
          },
          thStyle: {
            width: '15%',
          },
          sortable: true,
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
          tdClass: 'text-center align-middle',
          thClass: 'text-center',
          thStyle: {
            width: '10%',
          },
          tdStyle: {
            width: '10%',
          },
          sortable: true,
          formatter: (string) => {
            return convertPrice(string);
          },
        },
      ];
    },
    fetchInstallmentPrograms() {
      const params = {};
      ApiService.query('installment-programs', {
        params: params,
      })
        .then((response) => {
          const { data } = response.data.data;
          const dataMapping = data.map((item) => {
            item.currentPage = 1;
            item.products = [];
            return item;
          });
          this.installmentPrograms = dataMapping;
          return dataMapping;
        })
        .then(async (installmentPrograms) => {
          const fisrtProgram = cloneDeep(installmentPrograms).shift();
          this.fetchInstallmentProducts(0, fisrtProgram.id);
        });
    },
    fetchInstallmentProducts(index = 0, programId) {
      this.isBusy = true;
      return ApiService.get(`installment-programs/${programId}`)
        .then((response) => {
          const { data } = response.data;
          this.fieldsProductsProgram = this.getFieldProductPrograms();
          this.installmentPrograms[index].products = data.products;
          this.installmentPrograms[index].categories = data.categories;
          this.choiceProgram = this.installmentPrograms[index];
        })
        .finally(() => {
          setTimeout(() => {
            this.isBusy = false;
          }, 500);
        });
    },
    fetchProgramByProductIds() {
      const orderItems = cloneDeep(this.getProducts);
      const productIds = orderItems.map((item) => item.productId);
      if (productIds.length) {
        const params = {
          productIds: productIds,
        };
        ApiService.query('installment-programs/product-ids', {
          params: params,
        }).then((response) => {
          const { data } = response.data;
          this.orderItems = orderItems.map((item) => {
            const itemFinded = data.find(
              (product) => product.productId === item.productId
            );
            item.listedPrice = itemFinded ? itemFinded.programPrice : 0;
            item.programs = itemFinded ? itemFinded.programs : [];
            item.btnRadioSelected = false;
            return item;
          });
        });
      }
    },
    onAddPayments() {
      if (this.paymentType) {
        EventBus.$emit(`add-payments-${this.paymentType}`, this.paymentType);
        this.$bvModal.hide(this.refModal);
      }
    },
    onClickRadioButtonSelected(item) {
      this.orderItems.forEach((element) => {
        if (element.id === item.id) {
          element.btnRadioSelected = true;
        } else {
          element.btnRadioSelected = false;
        }
      });
    },
    onClickTab(index, programId) {
      this.fetchInstallmentProducts(index, programId);
    },
    onChangeTabHeader(tabId) {
      const navChilds = document.getElementsByClassName('nav-child');
      navChilds.forEach((child) => {
        if (child) {
          if (
            child.classList.contains('d-block') ||
            child.classList.contains('d-none')
          ) {
            child.classList.remove('d-block', 'd-none');
          }
          if (child && tabId === child.id) {
            child.classList.add('d-block');
          } else {
            child.classList.add('d-none');
          }
        }
      });
    },
    async shownInterestProgramModal() {
      await this.$refs[this.refModal].show();
      this.setUpTabModal();
    },
  },
};
</script>
<style lang="scss" scoped>
#modal-installment-common {
  .nav .nav-link.active {
    border-bottom: 2px solid #2196f3;
  }
  .nav-tabs .nav-link {
    border: none;
  }
  .nav-child {
    display: none;
  }
}
</style>