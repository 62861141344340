
const linkMixin = {
  created: function() {},
  methods: {
    linkToPreOrder: function (id) {
        const { href } = this.$router.resolve({
          name: 'pre-order',
          params: { id: id },
        });
        window.open(href, '_blank');
      },
    
  }
};
export default linkMixin;
