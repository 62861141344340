export function generateImage(formData) {
  const photos = formData.getAll('photos');
  const promises = photos.map(photo =>
    createImageTag(photo).then(img => ({
      id: img,
      originalName: photo.name,
      fileName: photo.name,
      url: img
    }))
  );
  return Promise.all(promises);
}

export function createImageTag(file) {
  return new Promise(resolve => {
    const fReader = new FileReader();
    const img = document.createElement('img');

    fReader.onload = () => {
      img.src = fReader.result;
      resolve(createCanvas(img));
    };

    fReader.readAsDataURL(file);
  });
}

export function createCanvas(img) {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0);
  const dataURL = img.src;
  return dataURL;
}
//TODO: bo khi chay dc thanh cong
export function typecheck(oInput) {
  var _validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];
  if (oInput.length > 0) {
    var blnValid = false;
    for (var j = 0; j < _validFileExtensions.length; j++) {
      var sCurExtension = _validFileExtensions[j];
      if (oInput.substr(oInput.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
        blnValid = true;
        break;
      }
    }
    if (!blnValid) {
      return false;
    }
  }
  return true;
}

export function checkTypeFile(file) {
  const validExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];
  if (file.length > 0) {
    for (const extension of validExtensions) {
      const currentExtension = file.substr(file.length - extension.length, extension.length);
      if (currentExtension.toLowerCase() === extension.toLowerCase()) {
        return true;
      }
    }
  }
  return false;
}

export function ftruncate(n) {
  // var len = 10;
  const ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
  const filename = n.replace('.' + ext, '');
  // if (filename.length <= len) {
  //   return n;
  // }
  // filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
  return filename + '.' + ext;
}
